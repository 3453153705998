import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    Typography,
    TablePagination,
    TableContainer,
} from '@mui/material';
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import axios from 'axios';
import config from '../../services/config';
import GrnDetail from './GrnDetail';
import log from 'loglevel';

const GrnSearch = ({ open, onClose }) => {
    const [searchInput, setSearchInput] = useState('');
    const [allGRNs, setAllGRNs] = useState([]);
    const [filteredGRNs, setFilteredGRNs] = useState([]);
    const [selectedGrn, setSelectedGrn] = useState(null);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);

    // Pagination states
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page
    log.setLevel('info');

    useEffect(() => {
        const fetchGRNs = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/grn/getallgrn`);
                setAllGRNs(response.data);
                setFilteredGRNs(response.data); // Initialize filtered GRNs with all
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error fetching GRNs:', error);
                }
                log.error('Error fetching GRNs:', error);
            }
        };

        if (open) {
            fetchGRNs();
        }
    }, [open]);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchInput(value);

        const filtered = allGRNs.filter(grn =>
            grn.date.includes(value.toLowerCase()) ||
            grn.supplierName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredGRNs(filtered);
        setPage(0); // Reset to the first page whenever the search input changes
    };

    const handleDetailOpen = (grn) => {
        setSelectedGrn(grn);
        setDetailDialogOpen(true);
    };

    const handleDetailClose = () => {
        setDetailDialogOpen(false);
        setSelectedGrn(null);
    };

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };

    // Calculate current GRNs for the current page
    const currentGRNs = filteredGRNs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                <Typography variant="h6" component="span">Search GRN</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ mt: 2, mb: 2 }}
                    label="Search by GRN No / Supplier"
                    variant="outlined"
                    fullWidth
                    value={searchInput}
                    onChange={handleSearchInputChange}
                />
                <Paper elevation={2} sx={{ borderRadius: 2 }}>
                    <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#424242' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }}>Supplier</TableCell>
                                    <TableCell style={{ color: 'white' }}>Date</TableCell>
                                    <TableCell style={{ color: 'white' }} align="right">Grand Total (Rs.)</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentGRNs.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            <Typography variant="body2" color="textSecondary">No data available</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    currentGRNs.map((grn) => (
                                        <TableRow key={grn.grnNo}>
                                            <TableCell>{grn.supplierName}</TableCell>
                                            <TableCell>{grn.date}</TableCell>
                                            <TableCell align='right'>{parseFloat(grn.grandTotal).toFixed(2)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="View Items" arrow>
                                                    <IconButton color='primary' onClick={() => handleDetailOpen(grn)}>
                                                        <ShoppingCartIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredGRNs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>

            {/* GrnDetail Dialog */}
            <GrnDetail
                open={detailDialogOpen}
                onClose={handleDetailClose}
                grn={selectedGrn}
            />
        </Dialog>
    );
};

export default GrnSearch;
