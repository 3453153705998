import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Divider,
  Grid,
  Checkbox,
  TextField,
  InputAdornment,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const ItemPriceUpdate = ({ open, onClose, item, branchId, sysUser }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [pricingDetails, setPricingDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newBuyingPrice, setNewBuyingPrice] = useState('0.00');
  const [newSellingPrice, setNewSellingPrice] = useState('0.00');
  log.setLevel('info');

  // State for alerts
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');

  // State for confirmation alert
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmType, setConfirmType] = useState(false);

  // Create refs for input fields
  const buyingPriceRef = useRef(null);
  const sellingPriceRef = useRef(null);

  const fetchPricingDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.API_BASE_URL}/stock/getallitembatch`, {
        params: { itemCode: item.itemCode },
      });
      setPricingDetails(response.data);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error fetching pricing details:', error);
      }
      log.error('Error fetching pricing details:', error);
    } finally {
      setLoading(false);
    }
  }, [item.itemCode]);

  useEffect(() => {
    if (open) {
      fetchPricingDetails();
      setNewBuyingPrice('0.00');
      setNewSellingPrice('0.00');
    }
  }, [open, fetchPricingDetails]);

  const handleSelectionChange = (itemBatchId, buyingPrice, sellingPrice) => {
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.find((item) => item.itemBatchId === itemBatchId);

      if (isSelected) {
        return prevSelected.filter((item) => item.itemBatchId !== itemBatchId);
      } else {
        return [...prevSelected, { itemBatchId, buyingPrice, sellingPrice }];
      }
    });
  };

  const handleUpdatePrices = async () => {

    const buyingPrice = parseFloat(newBuyingPrice);
    const sellingPrice = parseFloat(newSellingPrice);

    if (selectedItems.length === 0) {
      setAlertMessage('Please select at least one batch.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }
    if (buyingPrice <= 0) {
      setAlertMessage('Please enter a buying price greater than 0.');
      setAlertSeverity('error');
      setAlertOpen(true);
      buyingPriceRef.current.focus();
      return;
    }
    if (sellingPrice <= 0) {
      setAlertMessage('Please enter a selling price greater than 0.');
      setAlertSeverity('error');
      setAlertOpen(true);
      sellingPriceRef.current.focus();
      return;
    }
    if (sellingPrice <= buyingPrice) {
      setAlertMessage('Please enter a selling price greater than the buying price.');
      setAlertSeverity('error');
      setAlertOpen(true);
      sellingPriceRef.current.focus();
      return;
    }

    setConfirmOpen(true);
    setConfirmType("S");
  };

  const handleConfirmUpdatePrices = async () => {

    try {
      const updatedItems = selectedItems.map(item => ({
        itemBatchId: item.itemBatchId,
        oldBuyingPrice: item.buyingPrice,
        oldSellingPrice: item.sellingPrice,

      }));

      const data = {
        items: updatedItems,
        newBuyingPrice: parseFloat(newBuyingPrice),
        newSellingPrice: parseFloat(newSellingPrice),
        sysUser: sysUser,
      };

      const response = await axios.post(`${config.API_BASE_URL}/stock/updateprice`, { data: data });

      setAlertMessage(response.data.message);
      setAlertSeverity(response.data.success ? 'success' : 'error');
      setAlertOpen(true);

      if (response.data.success) {
        await fetchPricingDetails();
        setSelectedItems([]);
        setNewBuyingPrice('0.00');
        setNewSellingPrice('0.00');
      }
    } catch (error) {
      if (error.response) {
        setAlertMessage(error.response.data.message);
        setAlertSeverity('error');
      } else {
        setAlertMessage("Network error. Please check your internet connection.");
        setAlertSeverity('error');
      }
      setAlertOpen(true);
      if (process.env.NODE_ENV === 'development') {
        console.error('Error updating price:', error);
      }
      log.error('Error updating price:', error);
    } finally {
      setConfirmOpen(false);
    }
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const handleBuyingPriceChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setNewBuyingPrice(value);
    }
  };

  const handleSellingPriceChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setNewSellingPrice(value);
    }
  };

  const handleBuyingPriceBlur = () => {
    if (!newBuyingPrice || newBuyingPrice === '0.00') {
      setNewBuyingPrice('0.00');
    }
  };

  const handleSellingPriceBlur = () => {
    if (!newSellingPrice || newSellingPrice === '0.00') {
      setNewSellingPrice('0.00');
    }
  };

  const selectAllText = (ref) => {
    if (ref.current) {
      ref.current.select();
    }
  };

  const handleClose = () => {
    setNewBuyingPrice('0.00');
    setNewSellingPrice('0.00');
    setSelectedItems([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>Update Price</DialogTitle>

      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Code:</strong> {item.itemCode}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Name:</strong> {item.itemName}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Company:</strong> {item.company}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Type:</strong> {item.itemType}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Unit:</strong> {item.unit}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Available Qty:</strong> {item.availableQty}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Reminding Qty:</strong> {item.remindingQty}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Other Details:</strong> {item.otherDetails}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" sx={{ mb: 2 }}>
          Item Batches
        </Typography>
        <Table>
          <TableHead sx={{ bgcolor: '#424242' }}>
            <TableRow>
              <TableCell style={{ color: 'white' }}>Select</TableCell>
              <TableCell style={{ color: 'white' }}>Quantity</TableCell>
              <TableCell style={{ color: 'white' }} align="right">Buying Price (Rs.)</TableCell>
              <TableCell style={{ color: 'white' }} align="right">Selling Price (Rs.)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography>Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : pricingDetails.length > 0 ? (
              pricingDetails.map((detail) => (
                <TableRow key={detail.itemBatchId}>
                  <TableCell>
                    <Checkbox
                      checked={selectedItems.some(item => item.itemBatchId === detail.itemBatchId)}
                      onChange={() => handleSelectionChange(detail.itemBatchId, detail.buyingPrice, detail.sellingPrice)}
                    />
                  </TableCell>
                  <TableCell>{detail.qty}</TableCell>
                  <TableCell align="right">{formatNumber(detail.buyingPrice)}</TableCell>
                  <TableCell align="right">{formatNumber(detail.sellingPrice)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography>No pricing details found.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Update Prices
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="New Buying Price"
                value={newBuyingPrice}
                onChange={handleBuyingPriceChange}
                onFocus={() => selectAllText(buyingPriceRef)}
                onBlur={handleBuyingPriceBlur}
                inputRef={buyingPriceRef}
                fullWidth
                variant="outlined"
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="New Selling Price"
                value={newSellingPrice}
                onChange={handleSellingPriceChange}
                onFocus={() => selectAllText(sellingPriceRef)}
                onBlur={handleSellingPriceBlur}
                inputRef={sellingPriceRef}
                fullWidth
                variant="outlined"
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleUpdatePrices} variant="contained" color="primary" sx={{ color: 'font.main' }}>
          Update Prices
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Close
        </Button>
      </DialogActions>

      {/* Custom Alert */}
      <CustomAlert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        message={alertMessage}
        severity={alertSeverity}
      />

      {/* Confirmation Alert */}
      <CustomAlert
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        message={confirmType === 'S' ? "Do you want to update this price?" : ""}
        severity="warning"
        onConfirm={confirmType === 'S' ? handleConfirmUpdatePrices : null}
        isConfirmation
      />
    </Dialog>
  );
};

export default ItemPriceUpdate;
