import React, { useCallback, useEffect, useState } from 'react';
import {
    Button, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, ThemeProvider, Container, AppBar, Toolbar, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, CircularProgress
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const TransferApprove = ({ user, branchId }) => {
    const theme = useTheme();
    const [transfers, setTransfers] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState(null);
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    log.setLevel('info');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    const fetchTransfers = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/transfer/getbranchtransfers`, {
                params: { branchId },
            });
            setTransfers(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching transfer:', error);
            }
            log.error('Error fetching transfers:', error);
        }
    }, [branchId]);

    const fetchItems = async (transferId) => {
        setLoadingItems(true);
        try {
            const response = await axios.get(`${config.API_BASE_URL}/transfer/gettransferitems`, {
                params: { transferId },
            });
            setItems(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching items:', error);
            }
            log.error('Error fetching items:', error);
        } finally {
            setLoadingItems(false);
        }
    };

    const handleViewItems = (transfer) => {
        setSelectedTransfer(transfer);
        fetchItems(transfer.transferId); // Fetch items when opening the dialog
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedTransfer(null);
        setItems([]);
    };

    useEffect(() => {
        fetchTransfers();
    }, [fetchTransfers]);

    const approveTransfer = async () => {
        setConfirmOpen(true);
        setConfirmType("A");
    };

    const approveConfirmTransfer = async () => {

        try {
            const data = {
                transferId: selectedTransfer.transferId,
                items: items,
            };

            const response = await axios.post(`${config.API_BASE_URL}/transfer/approvetransfer`, { data: data, sysUser: user[0].User_Name, branchId: branchId });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                handleCloseDialog();
                fetchTransfers();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving transfer:', error);
            }
            log.error('Error approving transfer:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    // Helper function to format selling price
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (price % 1 ? price.toFixed(2).slice(-3) : ".00");
    };


    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            Approve Transfer
                        </Typography>
                    </Toolbar>
                </AppBar>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Transferred From</TableCell>
                                <TableCell style={{ color: 'white' }}>Transferred Date</TableCell>
                                <TableCell style={{ color: 'white' }}>Total Qty</TableCell>
                                <TableCell style={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transfers.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                transfers.map((transfer) => (
                                    <TableRow key={transfer.transferId} hover>
                                        <TableCell>{transfer.transferedFrom}</TableCell>
                                        <TableCell>{transfer.transferedDate}</TableCell>
                                        <TableCell>{transfer.totalQty}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleViewItems(transfer)}
                                            >
                                                View Items
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogTitle>Transfer Details</DialogTitle>
                    <DialogContent>
                        {selectedTransfer && (
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Transfer Information
                                    </Typography>
                                    <Typography><strong>Transferred From:</strong> {selectedTransfer.transferedFrom}</Typography>
                                    <Typography><strong>Transferred Date:</strong> {selectedTransfer.transferedDate}</Typography>
                                    <Typography><strong>Total Qty:</strong> {selectedTransfer.totalQty}</Typography>
                                </CardContent>
                            </Card>
                        )}

                        <Typography variant="h6" gutterBottom>
                            Items
                        </Typography>
                        {loadingItems ? (
                            <CircularProgress />
                        ) : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item Code</TableCell>
                                        <TableCell>Item Name</TableCell>
                                        <TableCell align="right">Selling Price</TableCell>
                                        <TableCell>Qty</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map(item => (
                                        <TableRow key={item.itemCode}>
                                            <TableCell>{item.itemCode}</TableCell>
                                            <TableCell>{item.itemName}</TableCell>
                                            <TableCell align="right">{formatPrice(item.sellingPrice)}</TableCell>
                                            <TableCell>{item.qty}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" variant='outlined'>Cancel</Button>
                        <Button onClick={approveTransfer} variant="contained" color="primary" sx={{ color: 'font.main' }}>Approve</Button>
                    </DialogActions>
                </Dialog>

                {/* Custom Alert */}
                <CustomAlert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                {/* Confirmation Alert */}
                <CustomAlert
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    message={confirmType === 'A' ? "Do you want to approve this transfer?" : ""}
                    severity="warning"
                    onConfirm={confirmType === 'A' ? approveConfirmTransfer : null}
                    isConfirmation
                />

            </Container>
        </ThemeProvider>
    );
};

export default TransferApprove;
