import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, AppBar, Toolbar } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssessmentIcon from "@mui/icons-material/Assessment";
import axios from "axios";
import config from '../../services/config';

const Dashboard = ({ user, branchId, }) => {

    const [summaryData, setSummaryData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [outstandingCustomers, setOutstandingCustomers] = useState([]);
    const [values, setValues] = useState({
        dailySales: "0.00",
        monthlySales: "0.00",
        annualSales: "0.00",
        dailyPurchases: "0.00",
        monthlyPurchases: "0.00",
        annualPurchases: "0.00",
        dailyExpenses: "0.00",
        monthlyExpenses: "0.00",
        annualExpenses: "0.00",
    });

    const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#ff9999"];

    // Fetch data from API
    useEffect(() => {
        axios.get(`${config.API_BASE_URL}/dashboard/getsummarydata`, { params: { branchId } }) // Replace with your actual API endpoint
            .then((response) => {
                const data = response.data;
                setSummaryData(data.summary);
                setProductData(data.topSellingProducts);
                setOutstandingCustomers(data.outstandingCustomers);
                setValues({

                    dailySales: `${data.sales[0].daily}`,
                    monthlySales: `${data.sales[0].monthly}`,
                    annualSales: `${data.sales[0].annual}`,
                    dailyPurchases: `${data.purchases[0].daily}`,
                    monthlyPurchases: `${data.purchases[0].monthly}`,
                    annualPurchases: `${data.purchases[0].annual}`,
                    dailyExpenses: ` ${data.expenses[0].daily}`,
                    monthlyExpenses: `${data.expenses[0].monthly}`,
                    annualExpenses: `${data.expenses[0].annual}`,
                });
            })
            .catch((error) => {
                console.error("Error fetching dashboard data:", error);
            });
    }, [branchId]);

    return (
        <Container maxWidth="lg" sx={{ mb: 4 }}>
            <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Top Statistics Cards */}
            {user[0].User_Type !== "User" && (
                <Grid container spacing={3}>
                    {/* Sales Overview */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                                Sales Overview
                            </Typography>
                            <Grid container spacing={2}>
                                {/* Sales Cards */}
                                {[
                                    {
                                        label: "Daily Sales",
                                        value: `Rs. ${parseFloat(values.dailySales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#E3F2FD", color: "#2196F3"
                                    },
                                    {
                                        label: "Monthly Sales",
                                        value: `Rs. ${parseFloat(values.monthlySales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#C8E6C9", color: "#4CAF50"
                                    },
                                    {
                                        label: "Annual Sales",
                                        value: `Rs. ${parseFloat(values.annualSales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#FFCCBC", color: "#FF5722"
                                    },
                                ].map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Paper
                                            elevation={0}
                                            sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 2, backgroundColor: item.bg }}
                                        >
                                            <Box
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: item.color,
                                                    marginRight: 2,
                                                }}
                                            >
                                                <ShoppingCartIcon />
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {item.label}
                                                </Typography>
                                                <Typography variant="h6" fontWeight="bold">
                                                    {item.value}
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Purchases Overview */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                                Purchases Overview
                            </Typography>
                            <Grid container spacing={2}>
                                {/* Purchases Cards */}
                                {[
                                    {
                                        label: "Daily Purchases",
                                        value: `Rs. ${parseFloat(values.dailyPurchases).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#FFEBEE", color: "#E57373"
                                    },
                                    {
                                        label: "Monthly Purchases",
                                        value: `Rs. ${parseFloat(values.monthlyPurchases).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#C5CAE9", color: "#3F51B5"
                                    },
                                    {
                                        label: "Annual Purchases",
                                        value: `Rs. ${parseFloat(values.annualPurchases).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#F8BBD0", color: "#D81B60"
                                    },
                                ].map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Paper
                                            elevation={0}
                                            sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 2, backgroundColor: item.bg }}
                                        >
                                            <Box
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: item.color,
                                                    marginRight: 2,
                                                }}
                                            >
                                                <AttachMoneyIcon />
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {item.label}
                                                </Typography>
                                                <Typography variant="h6" fontWeight="bold">
                                                    {item.value}
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Expenses Overview */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                                Expenses Overview
                            </Typography>
                            <Grid container spacing={2}>
                                {/* Expenses Cards */}
                                {[
                                    {
                                        label: "Daily Expenses",
                                        value: `Rs. ${parseFloat(values.dailyExpenses).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#E8F5E9", color: "#4CAF50"
                                    },
                                    {
                                        label: "Monthly Expenses",
                                        value: `Rs. ${parseFloat(values.monthlyExpenses).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#FFF9C4", color: "#FF9800"
                                    },
                                    {
                                        label: "Annual Expenses",
                                        value: `Rs. ${parseFloat(values.annualExpenses).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                        bg: "#D1C4E9", color: "#673AB7"
                                    },
                                ].map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Paper
                                            elevation={0}
                                            sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 2, backgroundColor: item.bg }}
                                        >
                                            <Box
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: item.color,
                                                    marginRight: 2,
                                                }}
                                            >
                                                <AssessmentIcon />
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {item.label}
                                                </Typography>
                                                <Typography variant="h6" fontWeight="bold">
                                                    {item.value}
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            )}

            {/* Charts Section */}
            <Grid container spacing={3} sx={{ mt: 3 }}>
                {/* Sales, Purchases, and Expenses Bar Chart */}
                {user[0].User_Type !== "User" && (
                    <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Sales, Purchases, and Expenses
                            </Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={summaryData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="Month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Sales" fill="#8884d8" />
                                    <Bar dataKey="Purchases" fill="#82ca9d" />
                                    <Bar dataKey="Expenses" fill="#ffc658" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                )}

                {/* Top-Selling Products Pie Chart */}
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Top Selling Products
                        </Typography>
                        <PieChart width={300} height={300}>
                            <Pie
                                data={productData}
                                dataKey="amount"
                                nameKey="itemName"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#8884d8"
                            >
                                {productData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Tooltip
                                formatter={(value, name) =>
                                    user[0].User_Type !== "User" ? `Rs. ${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ""
                                }
                            />
                            <Legend />
                        </PieChart>
                    </Paper>
                </Grid>

                {/* Outstanding Customers Table */}
                <Grid item xs={12} {...(user[0].User_Type === "User" ? { md: 8 } : {})}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Top 10 Customers with Outstanding Balances
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>#</strong></TableCell>
                                        <TableCell><strong>Customer Name</strong></TableCell>
                                        <TableCell><strong>Contact No</strong></TableCell>
                                        <TableCell align="right"><strong>Outstanding Balance (Rs.)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {outstandingCustomers.map((customer, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{customer.customerName}</TableCell>
                                            <TableCell>{customer.contactNo}</TableCell>
                                            <TableCell align="right">
                                                {parseFloat(customer.outstandingBalance).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;