import React, { useState, useEffect, useCallback } from 'react';
import config from '../../services/config';
import axios from 'axios';
import log from 'loglevel';

const DailyCashDetailsReport = React.forwardRef(({ branchId, records, columns, branchName, fromDate, toDate }, ref) => {

    const [branch, setBranch] = useState(null);
    log.setLevel('info');



    const fetchBranch = useCallback(async () => {
        try {
            const branchResponse = await axios.get(`${config.API_BASE_URL}/branch/getbranchbyid`, { params: { branchId } });
            setBranch(branchResponse.data[0]);

        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching branch:', error);
            }
            log.error('Error fetching branch:', error);
        }
    }, [branchId]);

    useEffect(() => {
        fetchBranch();
    }, [fetchBranch]);

    if (!records || !columns) {
        return <div>No record(s) found.</div>;
    }

    // Render the BillPrint component
    return (
        <div ref={ref} className="page">
            <div className="header">
                <img alt="Company Logo" className="logo" src="/Logo_Small.png" />
                {branch ? (
                    <>
                        <div className="branchname">{branch.branchName}</div>
                        <div className="address">{branch.address}</div>
                        <div className="contact">{branch.contactNo}</div>
                    </>
                ) : (
                    <div>Loading branch details...</div>
                )}
            </div>

            <div className="content">
                <div className="report-header">
                    <hr />
                    <div className="title">
                        <div>Daily Cash Details Report</div>
                    </div>
                    <div className="filters">
                        <div>Branch : {branchName}</div>
                        <div>From : {fromDate}</div>
                        <div>To : {toDate}</div>
                    </div>
                </div>
                <div className="table-container">
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Branch</th>
                                <th>Date</th>
                                <th>User</th>
                                <th style={{ textAlign: "right" }}>Cash Float(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Owner's Draw(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Total Cash(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Prev. Day Cash Float(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Cash of Sale(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Extra Income(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Cash Return(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Extra Expenses(Rs.)</th>
                                <th style={{ textAlign: "right" }}>Total Cash Income(Rs.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((record, index) => (
                                <tr key={record.date || index}>
                                    <td>{record.branchName}</td>
                                    <td>{record.date}</td>
                                    <td>{record.userName}</td>
                                    <td align='right'>{record.cashFloat}</td>
                                    <td align='right'>{record.ownersDraw}</td>
                                    <td align='right'>{record.totalCash}</td>
                                    <td align='right'>{record.prevDayCashFloat}</td>
                                    <td align='right'>{record.cashOfSale}</td>
                                    <td align='right'>{record.extraIncome}</td>
                                    <td align='right'>{record.cashReturn}</td>
                                    <td align='right'>{record.extraExpenses}</td>
                                    <td align='right'>{record.totalCashIncome}</td>
                                </tr>
                            ))}
                            {records.length > 0 && (
                                <tr style={{ fontWeight: "bold" }}>
                                    <td colSpan="11"></td>
                                    <td align="right">
                                        <hr style={{ borderTop: "1px solid black", margin: "5px 0" }} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                                            records.reduce((sum, row) => {
                                                const totalCashIncome = row.totalCashIncome ? Number(row.totalCashIncome.toString().replace(/,/g, '')) : 0;
                                                return sum + totalCashIncome;
                                            }, 0)
                                        )}
                                        <hr style={{ borderTop: "3px double black", margin: "5px 0" }} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Footer Section */}
            <div className="footer">
                <hr />
                <div>Software by VeloceCode - 0714589293 / 0773075020</div>
                <div>Copyright &copy; 2020-{new Date().getFullYear()} Lap Tech. All Rights Reserved.</div>
            </div>
        </div>
    );
});

export default DailyCashDetailsReport;
