import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Container,
    Grid,
    TextField,
    Button,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    AppBar,
    Toolbar,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    DialogActions,
    InputAdornment,
    TableContainer,
    Tooltip,
    IconButton,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import Payment from './Payment';
import log from 'loglevel';
import InvoiceSearch from './InvoiceSearch';
import { printInvoice } from '../utils/PrintUtils';
import DeleteIcon from '@mui/icons-material/Delete';

const Invoice = ({ user, branchId }) => {
    const [invoiceNo, setInvoiceNo] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [items, setItems] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemDetails, setItemDetails] = useState({
        branchStockId: '',
        itemBatchId: '',
        itemCode: '',
        itemName: '',
        qty: 0,
        unit: '',
        availableQty: 0,
        buyingPrice: (0.00).toFixed(2),
        sellingPrice: (0.00).toFixed(2),
        discount: 0,
    });
    const [msgData, setMsgData] = useState({
        cashAmount: 0,
        cashReceived: 0,
        balance: 0,
    });
    const [subTotal, setSubTotal] = useState((0.00).toFixed(2));
    const [excessFunds, setExcessFunds] = useState((0.00).toFixed(2));
    const [itemBatches, setItemBatches] = useState([]);
    const [batchDialogOpen, setBatchDialogOpen] = useState(false);
    const [selectedBatchIndex, setSelectedBatchIndex] = useState(0);
    const [paymentData, setPaymentData] = useState({});
    const [creditAllow, setCreditAllow] = useState({});

    log.setLevel('info');
    const iframeRef = useRef();

    const [highlightedAlertOpen, setHighlightedAlertOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    const [searchDialogOpen, setSearchDialogOpen] = useState(false);

    const customerRef = useRef(null);
    const itemRef = useRef(null);
    const qtyRef = useRef(null);
    const discountRef = useRef(null);
    const sellingPriceRef = useRef(null);
    const cashReceivedRef = useRef(null);
    const cashRef = useRef(null);
    const cashAmountRef = useRef(null);
    const creditRef = useRef(null);
    const creditAmountRef = useRef(null);
    const creditPeriodRef = useRef(null);
    const totalDiscountRef = useRef(null);
    const [clearPayment, setClearPayment] = useState(false);

    const handleClear = () => {
        setClearPayment(true);
        setTimeout(() => setClearPayment(false), 0);
    };

    useEffect(() => {
        const total = invoiceItems.reduce((acc, item) => acc + parseFloat(item.subtotal), 0).toFixed(2);
        setSubTotal(total);
    }, [invoiceItems]);

    const fetchData = useCallback(async () => {
        try {
            const invoiceResponse = await axios.get(`${config.API_BASE_URL}/invoice/getinvoiceno`, { params: { branchId } });
            setInvoiceNo(invoiceResponse.data[0].invoiceNo);

            const customersResponse = await axios.get(`${config.API_BASE_URL}/customer/getcustomersforcombo`);
            setCustomers(customersResponse.data);

            const itemsResponse = await axios.get(`${config.API_BASE_URL}/stock/getbranchitemswithprice`, { params: { branchId } });
            const groupedItems = {};
            itemsResponse.data.forEach(item => {
                const key = `${item.itemCode} - ${item.itemName}`;
                if (!groupedItems[key]) {
                    groupedItems[key] = [];
                }
                groupedItems[key].push(item);
            });
            setItems(Object.entries(groupedItems).map(([label, batches]) => ({ label, batches })));
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching data:', error);
            }
            log.error('Error fetching data:', error);
        }
    }, [branchId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleItemSelect = (event, value) => {
        if (value) {
            const selected = value.batches;
            setSelectedItem(value);

            if (selected.length === 1) {
                const batch = selected[0];
                setItemDetails({
                    branchStockId: batch.branchStockId,
                    itemBatchId: batch.itemBatchId,
                    itemCode: batch.itemCode,
                    itemName: batch.itemName,
                    qty: 0,
                    unit: batch.unit,
                    availableQty: batch.availableQty,
                    buyingPrice: parseFloat(batch.buyingPrice),
                    sellingPrice: parseFloat(batch.sellingPrice),
                    discount: parseFloat(batch.discount),
                });
                if (qtyRef.current) {
                    qtyRef.current.focus();
                }
            } else {
                setItemBatches(selected);
                setBatchDialogOpen(true);
                setSelectedBatchIndex(0);
            }
        } else {
            setSelectedItem(null);
            clearFields();
        }
    };

    const handleBatchSelect = (batch) => {
        setItemDetails({
            branchStockId: batch.branchStockId,
            itemBatchId: batch.itemBatchId,
            itemCode: batch.itemCode,
            itemName: batch.itemName,
            qty: 0,
            unit: batch.unit,
            availableQty: batch.availableQty,
            buyingPrice: parseFloat(batch.buyingPrice),
            sellingPrice: parseFloat(batch.sellingPrice),
            discount: parseFloat(batch.discount),
        });
        setBatchDialogOpen(false);
        setTimeout(() => {
            if (qtyRef.current) {
                qtyRef.current.focus();
            }
        }, 0);
    };

    const handleBatchKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            setSelectedBatchIndex((prevIndex) => Math.min(prevIndex + 1, itemBatches.length - 1));
        } else if (event.key === 'ArrowUp') {
            setSelectedBatchIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (event.key === 'Enter') {
            handleBatchSelect(itemBatches[selectedBatchIndex]);
        } else if (event.key === 'Escape') {
            setBatchDialogOpen(false);
        }
    };

    useEffect(() => {
        const subtotal = (itemDetails.qty * itemDetails.buyingPrice).toFixed(2);
        setItemDetails(prevDetails => ({
            ...prevDetails,
            subtotal,
        }));
    }, [itemDetails.qty, itemDetails.buyingPrice]);

    useEffect(() => {
        if (customerRef.current) {
            customerRef.current.focus();
        }
    }, []);

    const handleCustomerSelect = (event, value) => {
        if (value) {
            setSelectedCustomer(value);
            setExcessFunds(value.excessFunds);
            if (value.customerId === 1) {
                setCreditAllow(false);
            } else {
                setCreditAllow(true);
            }
        } else {
            setSelectedCustomer(null);
        }
    };

    useEffect(() => {
        const defaultCustomer = customers.find(customer => customer.customerId === 1);
        if (defaultCustomer) {
            setSelectedCustomer(defaultCustomer);
            setCreditAllow(false);
        }
    }, [customers]);

    const validateInteger = (value) => {
        return /^\d*$/.test(value);
    };

    const validateDecimal = (value) => {
        return /^\d*\.?\d*$/.test(value);
    };

    const handleInputChange = (field, value) => {
        if (field === 'qty' && validateInteger(value)) {
            setItemDetails(prev => ({ ...prev, qty: value }));
        } else if (field === 'discount' && validateDecimal(value)) {
            setItemDetails(prev => ({ ...prev, [field]: value }));
        }
    };

    const addItemToTable = () => {

        if (!selectedItem) {
            setAlertMessage('Please select an Item.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }
        if (itemDetails.qty <= 0) {
            setAlertMessage('Please enter a quantity greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            qtyRef.current.focus();
            return;
        }
        if (itemDetails.qty > itemDetails.availableQty) {
            setAlertMessage(`Please ensure the quantity does not exceed the available quantity (${itemDetails.availableQty}).`);
            setAlertSeverity('error');
            setAlertOpen(true);
            qtyRef.current.focus();
            return;
        }
        const exists = invoiceItems.some(item => item.itemBatchId === itemDetails.itemBatchId);
        if (exists) {
            setAlertMessage('This item has already been added to the table.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }

        setInvoiceItems(prevItems => [
            ...prevItems,
            {
                ...itemDetails,
                branchStockId: itemDetails.branchStockId,
                itemBatchId: itemDetails.itemBatchId,
                itemCode: itemDetails.itemCode,
                itemName: itemDetails.itemName,
                qty: itemDetails.qty,
                unit: itemDetails.unit,
                availableQty: itemDetails.availableQty,
                buyingPrice: parseFloat(itemDetails.buyingPrice).toFixed(2),
                sellingPrice: parseFloat(itemDetails.sellingPrice).toFixed(2),
                total: parseFloat(itemDetails.qty * itemDetails.sellingPrice).toFixed(2),
                discount: parseFloat(itemDetails.qty * itemDetails.discount).toFixed(2),
                subtotal: parseFloat((itemDetails.qty * itemDetails.sellingPrice) - (itemDetails.qty * itemDetails.discount)).toFixed(2),

            },
        ]);
        clearFields();

        if (itemRef.current) {
            itemRef.current.focus();
        }
    };

    const clearFields = () => {
        setItemDetails({
            branchStockId: '',
            itemBatchId: '',
            itemCode: '',
            itemName: '',
            qty: 0,
            unit: '',
            availableQty: 0,
            buyingPrice: (0.00).toFixed(2),
            sellingPrice: (0.00).toFixed(2),
            discount: (0.00).toFixed(2),
        });
        setSelectedItem(null);
    };

    const clearAll = () => {
        setConfirmOpen(true);
        setConfirmType("C");
    };
    const confirmClearAll = () => {
        setItemDetails({
            branchStockId: '',
            itemBatchId: '',
            itemCode: '',
            itemName: '',
            qty: 0,
            unit: '',
            availableQty: 0,
            buyingPrice: (0.00).toFixed(2),
            sellingPrice: (0.00).toFixed(2),
            discount: (0.00).toFixed(2),
        });
        setSelectedItem(null);
        setSelectedCustomer(null);
        setExcessFunds((0.00).toFixed(2));
        handleClear();
        setInvoiceItems([]);
        setConfirmOpen(false);
    };

    const removeItem = (index) => {
        setInvoiceItems(invoiceItems.filter((_, i) => i !== index));
    };

    const saveInvoice = async () => {

        const { cash, credit } = paymentData.paymentMethods;

        if (!selectedCustomer) {
            setAlertMessage('Please select a Customer.');
            setAlertSeverity('error');
            setAlertOpen(true);
            customerRef.current.focus();
            return;
        }
        if (invoiceItems.length === 0) {
            setAlertMessage('Please add at least one item to the Invoice before saving.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }
        if (!cash && !credit) {
            setAlertMessage('Please select at least one payment method before saving.');
            setAlertSeverity('error');
            setAlertOpen(true);
            cashRef.current.focus();
            return;
        }
        if (cash && paymentData.cashAmount <= 0) {
            setAlertMessage('Please enter a cash amount greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            cashAmountRef.current.focus();
            return;
        }
        if (credit && paymentData.creditAmount <= 0) {
            setAlertMessage('Please enter a credit amount greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            creditAmountRef.current.focus();
            return;
        }
        if ((parseFloat(paymentData.cashAmount) + parseFloat(paymentData.creditAmount)) !== parseFloat(paymentData.grandTotal)) {
            setAlertMessage('The total payment amount must equal the grand total.');
            setAlertSeverity('error');
            setAlertOpen(true);
            cashAmountRef.current.focus();
            return;
        }
        if (credit && paymentData.creditPeriod <= 0) {
            setAlertMessage('Please enter a credit period greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            creditPeriodRef.current.focus();
            return;
        }
        if (cash && parseFloat(paymentData.cashReceived) < parseFloat(paymentData.cashAmount)) {
            setAlertMessage('Please enter a cash received greater than cash amount.');
            setAlertSeverity('error');
            setAlertOpen(true);
            cashReceivedRef.current.focus();
            return;
        }
        if (cash && paymentData.cashReceived <= 0) {
            setAlertMessage('Please enter a cash received greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            cashReceivedRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("S");
    };

    const saveConfirmInvoice = async () => {

        try {

            const invoiceData = {
                customer: selectedCustomer,
                items: invoiceItems,
                payments: paymentData,
            };

            const response = await axios.post(`${config.API_BASE_URL}/invoice/saveinvoice`,
                {
                    invoiceData: invoiceData,
                    sysUser: user[0].User_Name,
                    loginId: user[0].Login_Id,
                    branchId: branchId
                });

            setMsgData({
                cashAmount: paymentData.cashAmount,
                cashReceived: paymentData.cashReceived,
                balance: paymentData.balance,
            });
            setHighlightedAlertOpen(true);

            if (response.data.success) {
                const invoiceData = response.data.invoiceData.invoice[0];
                const items = response.data.invoiceData.items;
                printInvoice(iframeRef, invoiceData, items, false);
                confirmClearAll();
                fetchData();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving invoice:', error);
            }
            log.error('Error saving invoice:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleFocus = (ref) => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select();
        }
    };

    const handlePaymentDataChange = (data) => {
        setPaymentData(data);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const handleSearchDialogOpen = () => {
        setSearchDialogOpen(true);
    };

    const handleSearchDialogClose = () => {
        setSearchDialogOpen(false);
    };

    const handleHighlightedAlertClose = () => {
        setHighlightedAlertOpen(false);
    };

    const highlightedAlertMessage = (
        <>
            <Typography variant="h6">The invoice has been saved successfully.</Typography>
            {msgData.cashAmount > 0 && (
                <>
                    <br />
                    <Typography variant="h6">Cash Amount: Rs. {msgData.cashAmount}</Typography>
                    <Typography variant="h6">Cash Received: Rs. {msgData.cashReceived}</Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                        Balance: Rs. {msgData.balance}
                    </Typography>
                </>
            )}
        </>
    );

    return (
        <Container>
            <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Invoice
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={3}>
                {/* Invoice No */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Invoice No"
                        type="text"
                        value={invoiceNo}
                        fullWidth
                        disabled
                    />
                </Grid>

                {/* Customer Selection */}
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={customers}
                        getOptionLabel={(customer) => customer.customerName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Customer"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={customerRef}
                                aria-hidden="false"
                            />
                        )}
                        value={selectedCustomer}
                        onChange={handleCustomerSelect}
                    />
                </Grid>

                {/* Item Code/Name Selection */}
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={items}
                        getOptionLabel={(item) => item.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Item Code / Name"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={itemRef}
                            />
                        )}
                        value={selectedItem}
                        onChange={handleItemSelect}
                    />
                </Grid>

                {/* Unit (Disabled) */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Unit"
                        type="text"
                        value={itemDetails.unit}
                        fullWidth
                        disabled
                    />
                </Grid>

                {/* Quantity */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        inputRef={qtyRef}
                        label="Quantity"
                        type="text"
                        value={itemDetails.qty}
                        onChange={(e) => handleInputChange('qty', e.target.value)}
                        onBlur={() => {
                            if (itemDetails.qty === '') {
                                setItemDetails((prev) => ({ ...prev, qty: 0 }));
                            }
                        }}
                        fullWidth
                        required
                        onFocus={() => handleFocus(qtyRef)}
                    />
                </Grid>

                {/* Selling Price (disabled) */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        inputRef={sellingPriceRef}
                        label="Selling Price"
                        type="text"
                        value={formatCurrency(itemDetails.sellingPrice)}
                        fullWidth
                        disabled
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                        }}
                    />
                </Grid>

                {/* Invoice Discount (disabled) */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        inputRef={discountRef}
                        label="Discount per Item"
                        type="text"
                        value={formatCurrency(itemDetails.discount)}
                        onChange={(e) => handleInputChange('discount', e.target.value)}
                        onBlur={() => {
                            if (itemDetails.discount === null || itemDetails.discount === '') {
                                setItemDetails((prev) => ({ ...prev, discount: 0 }));
                            }
                        }}
                        fullWidth
                        required
                        onFocus={() => handleFocus(discountRef)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                        }}
                    />
                </Grid>

                {/* Available Qty */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Available Qty"
                        type="text"
                        value={itemDetails.availableQty}
                        fullWidth
                        disabled
                    />
                </Grid>

                {/* Add Item Button */}
                <Grid item xs={12} sm={12}>
                    <Button variant="contained" color="primary" sx={{ color: 'font.main' }} onClick={addItemToTable}>
                        Add Item
                    </Button>
                </Grid>
            </Grid>

            <Paper sx={{ mt: 3 }}>
                <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Table >
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Item Code</TableCell>
                                <TableCell style={{ color: 'white' }}>Item Name</TableCell>
                                <TableCell style={{ color: 'white' }}>Unit</TableCell>
                                <TableCell style={{ color: 'white' }}>Quantity</TableCell>
                                <TableCell style={{ color: 'white' }}>Selling Price (Rs.)</TableCell>
                                <TableCell style={{ color: 'white' }}>Total (Rs.)</TableCell>
                                <TableCell style={{ color: 'white' }}>Discount (Rs.)</TableCell>
                                <TableCell style={{ color: 'white' }}>Subtotal (Rs.)</TableCell>
                                <TableCell style={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoiceItems.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                invoiceItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.itemCode}</TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.unit}</TableCell>
                                        <TableCell>{item.qty}</TableCell>
                                        <TableCell>{parseFloat(item.sellingPrice).toFixed(2)}</TableCell>
                                        <TableCell>{parseFloat(item.total).toFixed(2)}</TableCell>
                                        <TableCell>{parseFloat(item.discount).toFixed(2)}</TableCell>
                                        <TableCell>{parseFloat(item.subtotal).toFixed(2)}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Remove Item" arrow>
                                                <IconButton color="primary" onClick={() => removeItem(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Payment
                subTotal={subTotal}
                excessFunds={excessFunds}
                onPaymentDataChange={handlePaymentDataChange}
                cashReceivedRef={cashReceivedRef}
                cashRef={cashRef}
                cashAmountRef={cashAmountRef}
                creditRef={creditRef}
                creditAmountRef={creditAmountRef}
                creditPeriodRef={creditPeriodRef}
                totalDiscountRef={totalDiscountRef}
                creditAllow={creditAllow}
                clearPayment={clearPayment}
            />

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" color="primary" onClick={clearAll}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" sx={{ color: 'font.main' }} onClick={saveInvoice}>
                    Save & Print Invoice
                </Button>

                <Button variant="contained" color="primary" sx={{ color: 'font.main' }} onClick={handleSearchDialogOpen}>
                    Search Invoice
                </Button>

                <iframe
                    ref={iframeRef}
                    style={{ display: 'none', width: '0', height: '0' }}
                    title="print-frame"
                ></iframe>

            </Box>

            <Dialog open={batchDialogOpen} onClose={() => setBatchDialogOpen(false)} onKeyDown={handleBatchKeyDown}>
                <DialogTitle>Select a Batch</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {itemBatches.map((batch, index) => (
                            <Card
                                key={index}
                                sx={{
                                    cursor: 'pointer',
                                    border: selectedBatchIndex === index ? '2px solid #1976d2' : '1px solid lightgrey',
                                    transition: 'border 0.3s',
                                }}
                                onClick={() => handleBatchSelect(batch)}
                                onMouseEnter={() => setSelectedBatchIndex(index)}
                            >
                                <CardContent>
                                    <Typography variant="h6">{batch.itemName}</Typography>
                                    <Typography variant="body2">Available Qty: {batch.availableQty}</Typography>
                                    <Typography variant="body2">Unit: {batch.unit}</Typography>
                                    <Typography variant="body2">Buying Price: {formatCurrency(batch.buyingPrice)}</Typography>
                                    <Typography variant="body2">Selling Price: {formatCurrency(batch.sellingPrice)}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBatchDialogOpen(false)} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* GrnSearch component */}
            <InvoiceSearch
                open={searchDialogOpen}
                onClose={handleSearchDialogClose}
                branchId={branchId}
            />


            {/* Custom Alert */}
            <CustomAlert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                severity={alertSeverity}
            />

            <CustomAlert
                open={highlightedAlertOpen}
                onClose={handleHighlightedAlertClose}
                message={highlightedAlertMessage}
                severity="success"
                isConfirmation={false}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'S' ? "Do you want to save the Invoice?" :
                    confirmType === 'C' ? "Do you want to clear the Invoice?" : ""}
                severity="warning"
                onConfirm={confirmType === 'S' ? saveConfirmInvoice :
                    confirmType === 'C' ? confirmClearAll : null}
                isConfirmation
            />

        </Container>
    );
};

export default Invoice;
