import React, { useCallback, useEffect, useState } from 'react';
import {
    Button, TextField, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, ThemeProvider, Container, AppBar, Toolbar, Typography, Grid
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ReactPaginate from 'react-paginate';
import { useTheme } from '@mui/material/styles';
import UserUpdate from './UserUpdate';
import UserAdd from './UserAdd';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import UserChangePassword from './UserChangePassword';
import log from 'loglevel';

const User = ({ user, branchCount }) => {
    const userName = user[0].User_Name;
    const theme = useTheme();
    const [users, setUsers] = useState([]);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [passwordDialogVisible, setPasswordDialogVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const rowsPerPage = 10;
    log.setLevel('info');

    const [updateFormData, setUpdateFormData] = useState({
        nic: '',
        firstName: '',
        lastName: '',
        gender: '',
        mobileNo: '',
        homeNo: '',
        userTypeId: '',
        userType: '',
        branchId: '',
        branch: '',
        userName: '',
        password: '',
        confirmPassword: '',
        globalUser: false,
        status: false,
    });

    const fetchUsers = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/user/getallusers`, {
                params: { userType: user[0].User_Type, userName: user[0].User_Name },
            });
            setUsers(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching users:', error);
            }
            log.error('Error fetching users:', error);
        }
    }, [user]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const refreshGrid = () => {
        fetchUsers();
    };

    const filteredUser = users.filter(user =>
        user.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setPageCount(Math.ceil(filteredUser.length / rowsPerPage));
    }, [filteredUser]);

    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    const paginatedUsers = filteredUser.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    const handleEditUser = (userData) => {
        setUpdateFormData(userData);
        setUpdateVisible(true);
    };

    const handleChangePassword = (userData) => {
        setSelectedUser(userData);
        setPasswordDialogVisible(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            User Management
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {user[0].User_Type !== "User" && (
                            <Button
                                variant="contained" color="primary" sx={{ color: 'font.main' }}
                                fullWidth
                                onClick={() => setVisible(true)}
                            >
                                Add New User
                            </Button>
                        )}
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>NIC</TableCell>
                                <TableCell style={{ color: 'white' }}>Employee Name</TableCell>
                                <TableCell style={{ color: 'white' }}>User Name</TableCell>
                                <TableCell style={{ color: 'white' }}>User Type</TableCell>
                                {branchCount > 1 && (
                                    <>
                                        <TableCell style={{ color: 'white' }}>Branch</TableCell>
                                        <TableCell style={{ color: 'white' }}>Global User</TableCell>
                                    </>
                                )}
                                <TableCell style={{ color: 'white' }}>Status</TableCell>
                                <TableCell style={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedUsers.map((user) => (
                                <TableRow key={user.nic} hover>
                                    <TableCell>{user.nic}</TableCell>
                                    <TableCell>{(user.firstName || '') + ' ' + (user.lastName || '')}</TableCell>
                                    <TableCell>{user.userName}</TableCell>
                                    <TableCell>{user.userType}</TableCell>
                                    {branchCount > 1 && (
                                        <>
                                            <TableCell>{user.branch}</TableCell>
                                            <TableCell>{user.globalUser}</TableCell>
                                        </>
                                    )}
                                    <TableCell>{user.status}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit User Details">
                                            <IconButton color="primary" onClick={() => handleEditUser(user)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {user.userName === userName && (
                                            <Tooltip title="Change Password">
                                                <IconButton color="primary" onClick={() => handleChangePassword(user)}>
                                                    <LockResetIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                {pageCount > 0 && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        style={{ marginTop: '20px' }}
                    />
                )}

                {/* Add New User Dialog */}
                <UserAdd
                    visible={visible}
                    setVisible={setVisible}
                    user={user}
                    refreshGrid={refreshGrid}
                />

                {/* Update User Dialog */}
                <UserUpdate
                    open={updateVisible}
                    onClose={() => setUpdateVisible(false)}
                    updateFormData={updateFormData}
                    sysUser={user[0].User_Name}
                    refreshGrid={refreshGrid}
                    user={user}
                />

                {/* Change Password Dialog */}
                {selectedUser && (
                    <UserChangePassword
                        open={passwordDialogVisible}
                        onClose={() => setPasswordDialogVisible(false)}
                        userName={selectedUser.userName}
                        sysUser={user[0].User_Name}
                    />
                )}

            </Container>
        </ThemeProvider>
    );
};

export default User;
