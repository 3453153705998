import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Grid, Typography, TextField, MenuItem, Select, InputLabel, FormControl, Paper, AppBar, Toolbar, Container } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';
import axios from 'axios';
import { printReports } from '../utils/PrintUtils';

function Reports({ branchId, sysDate }) {
    const [selectedReport, setSelectedReport] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [fromDate, setFromDate] = useState(sysDate);
    const [toDate, setToDate] = useState(sysDate);
    const [year, setYear] = useState(new Date(sysDate).getFullYear());
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [branches, setBranches] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [columns, setColumns] = useState([]);
    const [records, setRecords] = useState([]);
    const [totalAmount, setTotalAmount] = useState((0).toFixed(2));

    log.setLevel('info');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    const reportRef = useRef(null);
    const branchRef = useRef(null);
    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);
    const yearRef = useRef(null);
    const customerRef = useRef(null);
    const iframeRef = useRef();

    const fetchData = useCallback(async () => {
        try {
            const branchesResponse = await axios.get(`${config.API_BASE_URL}/branch/getbranches`);
            const customersResponse = await axios.get(`${config.API_BASE_URL}/customer/getcustomersforcombo`);
            const branchesData = branchesResponse.data;
            const customersData = customersResponse.data.slice(1);

            if (branchesData.length > 1) {
                const allBranchesItem = {
                    branchId: '0',
                    branchName: 'All Branches'
                };

                setBranches([allBranchesItem, ...branchesData]);
            } else {
                setBranches(branchesData);
            }

            setCustomers(customersData);

        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching data:', error);
            }
            log.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (branches.length > 0) {
            // Set the first branch as the default value
            setSelectedBranch(branches[0]); // Assuming `setSelectedBranch` is your state setter
        }
    }, [branches]);

    const reportOptions = [
        { label: 'Daily Sale & Profit Detail Report', value: 'dailySaleProfitDetail' },
        { label: 'Daily Sale & Profit Summary Report', value: 'dailySaleProfitSummary' },
        { label: 'Sales Report', value: 'sales' },
        { label: 'Return Report', value: 'return' },
        { label: 'Sales Profit Report', value: 'salesProfit' },
        { label: 'Daily Cash Details Report', value: 'dailyCashDetails' },
        { label: 'Monthly Sale & Profit Report', value: 'monthlySaleProfit' },
        { label: 'Item wise Sales Report', value: 'itemWiseSales' },
        { label: 'Cash Details', value: 'cash' },
        { label: 'Purchase Report', value: 'purchase' },
        { label: 'Company Return/Damage Item', value: 'returnDamage' },
        { label: 'Customer wise Outstanding', value: 'customerWiseOutstanding' },
        { label: 'Extra Income & Expense', value: 'extraIncomeExpense' },
        { label: 'Price Update History', value: 'priceUpdateHistory' },
    ];

    const handleReportSelect = (event) => {
        const reportValue = event.target.value;
        setSelectedReport(reportValue);

        setRecords([]);
        setTotalAmount((0).toFixed(2));

        // Update columns based on the selected report
        if (reportValue === "dailySaleProfitDetail") {
            setColumns([
                ...(branches.length > 1
                    ? [{ field: 'branchName', headerName: 'Branch', flex: 1 }]
                    : []),
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'invoiceNo', headerName: 'Invoice No', flex: 1 },
                { field: 'invoiceAmount', headerName: 'Invoice Amount (Rs.)', flex: 1, type: 'number' },
                { field: 'profit', headerName: 'Profit (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "dailySaleProfitSummary") {
            setColumns([
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'noOfInvoices', headerName: 'No of Invoices', flex: 1 },
                { field: 'totalInvoiceAmount', headerName: 'Total Invoice Amount (Rs.)', flex: 1, type: 'number' },
                { field: 'profit', headerName: 'Profit (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "sales") {
            setColumns([
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'invoiceNo', headerName: 'Invoice No', flex: 1 },
                { field: 'customer', headerName: 'Customer', flex: 1 },
                { field: 'total', headerName: 'Total (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "return") {
            setColumns([
                { field: 'returnDate', headerName: 'Return Date', flex: 1 },
                { field: 'invoiceNo', headerName: 'Invoice No', flex: 1 },
                { field: 'goodQty', headerName: 'Good Qty', flex: 1 },
                { field: 'damageQty', headerName: 'Damage Qty', flex: 1 },
                { field: 'totalQty', headerName: 'Total Qty', flex: 1 },
                { field: 'totalAmount', headerName: 'Total Amount (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "salesProfit") {
            setColumns([
                { field: 'itemCode', headerName: 'Item Code', flex: 1 },
                { field: 'itemName', headerName: 'Item Name', flex: 1 },
                { field: 'qty', headerName: 'Qty', flex: 1 },
                { field: 'buyingPrice', headerName: 'Buying Price (Rs.)', flex: 1, type: 'number' },
                { field: 'buyingCost', headerName: 'Buying Cost (Rs.)', flex: 1, type: 'number' },
                { field: 'sellingPrice', headerName: 'Selling Price (Rs.)', flex: 1, type: 'number' },
                { field: 'totalDiscount', headerName: 'Total Discount (Rs.)', flex: 1, type: 'number' },
                { field: 'sellingTotal', headerName: 'Selling Total (Rs.)', flex: 1, type: 'number' },
                { field: 'profit', headerName: 'Profit (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "dailyCashDetails") {
            setColumns([
                ...(branches.length > 1
                    ? [{ field: 'branchName', headerName: 'Branch', flex: 1 }]
                    : []),
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'userName', headerName: 'User', flex: 1 },
                { field: 'cashFloat', headerName: 'Cash Float (Rs.)', flex: 1, type: 'number' },
                { field: 'ownersDraw', headerName: "Owner's Draw (Rs.)", flex: 1, type: 'number' },
                { field: 'totalCash', headerName: 'Total Cash (Rs.)', flex: 1, type: 'number' },
                { field: 'prevDayCashFloat', headerName: 'Prev. Day Cash Float (Rs.)', flex: 1, type: 'number' },
                { field: 'cashOfSale', headerName: 'Cash of Sale (Rs.)', flex: 1, type: 'number' },
                { field: 'extraIncome', headerName: 'Extra Income (Rs.)', flex: 1, type: 'number' },
                { field: 'cashReturn', headerName: 'Cash Return (Rs.)', flex: 1, type: 'number' },
                { field: 'extraExpenses', headerName: 'Extra Expenses (Rs.)', flex: 1, type: 'number' },
                { field: 'totalCashIncome', headerName: 'Total Cash Income (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "monthlySaleProfit") {
            setColumns([
                { field: 'month', headerName: 'Month', flex: 1 },
                { field: 'noOfInvoices', headerName: 'No of Invoices', flex: 1 },
                { field: 'totalInvoiceAmount', headerName: 'Total Invoice Amount (Rs.)', flex: 1, type: 'number' },
                { field: 'profit', headerName: 'Profit (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "itemWiseSales") {
            setColumns([
                { field: 'itemCode', headerName: 'Item Code', flex: 1 },
                { field: 'itemName', headerName: 'Item Name', flex: 1 },
                { field: 'qty', headerName: 'Qty', flex: 1 },
                { field: 'total', headerName: 'Total (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "cash") {
            setColumns([
                ...(branches.length > 1
                    ? [{ field: 'branchName', headerName: 'Branch', flex: 1 }]
                    : []),
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'cashFloat', headerName: 'Cash Float (Rs.)', flex: 1, type: 'number' },
                { field: 'ownersDraw', headerName: "Owner's Draw (Rs.)", flex: 1, type: 'number' },
                { field: 'userName', headerName: 'User', flex: 1 },
            ]);
        } else if (reportValue === "purchase") {
            setColumns([
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'noOfGrn', headerName: 'No of GRN', flex: 1 },
                { field: 'total', headerName: "Total (Rs.)", flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "returnDamage") {
            setColumns([
                ...(branches.length > 1
                    ? [{ field: 'branchName', headerName: 'Branch', flex: 1 }]
                    : []),
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'itemCode', headerName: 'Item Code', flex: 1 },
                { field: 'itemName', headerName: "Item Name", flex: 1 },
                { field: 'qty', headerName: 'Qty', flex: 1 },
                { field: 'reason', headerName: 'Reason', flex: 1 },
            ]);
        } else if (reportValue === "customerWiseOutstanding") {
            setColumns([
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'invoiceNo', headerName: 'Invoice No', flex: 1 },
                { field: 'invoiceTotal', headerName: 'Invoice Total (Rs.)', flex: 1, type: 'number' },
                { field: 'returnAmount', headerName: "Return Amount (Rs.)", flex: 1, type: 'number' },
                { field: 'payment', headerName: 'Payment (Rs.)', flex: 1, type: 'number' },
                { field: 'outstanding', headerName: 'Outstanding (Rs.)', flex: 1, type: 'number' },
            ]);
        } else if (reportValue === "extraIncomeExpense") {
            setColumns([
                ...(branches.length > 1
                    ? [{ field: 'branchName', headerName: 'Branch', flex: 1 }]
                    : []),
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'type', headerName: 'Type', flex: 1 },
                { field: 'description', headerName: 'Description', flex: 1 },
                { field: 'amount', headerName: "Amount (Rs.)", flex: 1, type: 'number' },
                { field: 'userName', headerName: 'User', flex: 1 },
                { field: 'comment', headerName: 'Comment', flex: 1 },
            ]);
        } else if (reportValue === "priceUpdateHistory") {
            setColumns([
                { field: 'date', headerName: 'Date', flex: 1 },
                { field: 'itemCode', headerName: 'Item Code', flex: 1 },
                { field: 'itemName', headerName: 'Item Name', flex: 1 },
                { field: 'oldBuyingPrice', headerName: 'Old Buying Price (Rs.)', flex: 1, type: 'number' },
                { field: 'oldSellingPrice', headerName: "Old Selling Price (Rs.)", flex: 1, type: 'number' },
                { field: 'newBuyingPrice', headerName: 'New Buying Price (Rs.)', flex: 1, type: 'number' },
                { field: 'newSellingPrice', headerName: 'New Selling Price (Rs.)', flex: 1, type: 'number' },
                { field: 'userName', headerName: 'User', flex: 1 },
            ]);
        }
    };

    const handleBranchSelect = (event) => {
        const branchId = event.target.value;
        const selected = branches.find(branch => branch.branchId === branchId);
        setSelectedBranch(selected || null);

        setRecords([]);
        setTotalAmount((0).toFixed(2));
    };

    const handleFromDateSelect = (event) => {
        const fromDate = event.target.value;
        setFromDate(fromDate);

        setRecords([]);
        setTotalAmount((0).toFixed(2));
    };

    const handleToDateSelect = (event) => {
        const toDate = event.target.value;
        setToDate(toDate);

        setRecords([]);
        setTotalAmount((0).toFixed(2));
    };

    const handleCustomerSelect = (event) => {
        const customerId = event.target.value;
        const selected = customers.find(customer => customer.customerId === customerId);
        setSelectedCustomer(selected || null);

        setRecords([]);
        setTotalAmount((0).toFixed(2));
    };

    const handleYearSelect = (event) => {
        const year = event.target.value;
        setYear(year)

        setRecords([]);
        setTotalAmount((0).toFixed(2));
    };

    const searchReport = async () => {
        try {

            if (!selectedReport) {
                setAlertMessage('Please select a Report.');
                setAlertSeverity('error');
                setAlertOpen(true);
                reportRef.current.focus();
                return;
            }
            if (selectedReport !== "purchase" && selectedReport !== "priceUpdateHistory" && !selectedBranch) {
                setAlertMessage('Please select a Branch.');
                setAlertSeverity('error');
                setAlertOpen(true);
                branchRef.current.focus();
                return;
            }
            if (!fromDate) {
                setAlertMessage('Please select a From Date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                fromDateRef.current.focus();
                return;
            }
            if (!toDate) {
                setAlertMessage('Please select a To Date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                toDateRef.current.focus();
                return;
            }

            const fromDateObj = new Date(fromDate);
            const toDateObj = new Date(toDate);
            const currentDate = new Date();

            if (fromDateObj > toDateObj) {
                setAlertMessage('From Date cannot be greater than To Date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                fromDateRef.current.focus();
                return;
            }
            if (toDateObj > currentDate) {
                setAlertMessage('To Date cannot be greater than the current system date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                toDateRef.current.focus();
                return;
            }

            const searchData = {
                report: selectedReport,
                branchId: selectedBranch.branchId,
                fromDate: fromDate,
                toDate: toDate,
                year: year,
                customerId: selectedCustomer.customerId,
            };

            // console.log("report " + searchData.report);
            // console.log("branchId " + searchData.branchId);
            // console.log("fromDate " + searchData.fromDate);
            // console.log("toDate " + searchData.toDate);
            // console.log("year " + searchData.year);
            // console.log("customerSupplierId " + searchData.customerSupplierId);

            const response = await axios.get(`${config.API_BASE_URL}/report/getreports`, { params: { searchData } });

            const dataWithId = response.data.map((row, index) => ({
                ...row,
                id: row.invoiceNo || index,
            }));
            if (dataWithId.length === 0) {
                setAlertMessage('No data found for the specified time period or filters.');
                setAlertSeverity('info');
                setAlertOpen(true);
            } else {
                setRecords(dataWithId);
                setTotalAmount(dataWithId.reduce((sum, row) => {

                    let value = 0;

                    if (selectedReport === "dailySaleProfitDetail" || selectedReport === "dailySaleProfitSummary" ||
                        selectedReport === "salesProfit" || selectedReport === "monthlySaleProfit") {
                        value = row.profit ? Number(row.profit.toString().replace(/,/g, '')) : 0;
                    } else if (selectedReport === "sales" || selectedReport === "itemWiseSales" ||
                        selectedReport === "purchase") {
                        value = row.total ? Number(row.total.toString().replace(/,/g, '')) : 0;
                    } else if (selectedReport === "return") {
                        value = row.totalAmount ? Number(row.totalAmount.toString().replace(/,/g, '')) : 0;
                    } else if (selectedReport === "dailyCashDetails") {
                        value = row.totalCashIncome ? Number(row.totalCashIncome.toString().replace(/,/g, '')) : 0;
                    } else if (selectedReport === "customerWiseOutstanding") {
                        value = row.outstanding ? Number(row.outstanding.toString().replace(/,/g, '')) : 0;
                    }

                    return sum + value;
                }, 0));
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const printReport = async () => {

        if (records.length === 0 || !records) {
            setAlertMessage('No record(s) found.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        setConfirmOpen(true);
        setConfirmType("P");
    };

    const printConfirmReport = async () => {
        try {
            printReports(iframeRef, selectedReport, branchId, records, columns, selectedBranch.branchName, fromDate, toDate, year, selectedCustomer.customerId);

        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    return (
        <Container>
            <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Reports
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper elevation={3} sx={{ padding: 3, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* 1st Row: Report & Branch */}
                    <Grid container item spacing={3} alignItems="center">
                        {/* Report Combo */}
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Report</InputLabel>
                                <Select
                                    value={selectedReport}
                                    label="Report"
                                    onChange={handleReportSelect}
                                    inputRef={reportRef}
                                >
                                    {reportOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Branch Combo */}
                        {(branches.length > 1 && selectedReport !== "purchase" && selectedReport !== "priceUpdateHistory") && (
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Branch</InputLabel>
                                    <Select
                                        value={selectedBranch ? selectedBranch.branchId : ''}
                                        label="Branch"
                                        onChange={handleBranchSelect}
                                        inputRef={branchRef}
                                    >
                                        {branches.map((branch) => (
                                            <MenuItem key={branch.branchId} value={branch.branchId}>
                                                {branch.branchName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {/* From Date */}
                        {selectedReport !== "monthlySaleProfit" && (
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    label={selectedReport === "dailySaleProfitDetail" ? "Date" : "From Date"}
                                    type="date"
                                    value={fromDate}
                                    onChange={handleFromDateSelect}
                                    fullWidth
                                    inputRef={fromDateRef}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ inputProps: { max: sysDate } }}
                                />
                            </Grid>
                        )}

                        {/* To Date */}
                        {selectedReport !== "dailySaleProfitDetail" && selectedReport !== "monthlySaleProfit" && (
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    label="To Date"
                                    type="date"
                                    value={toDate}
                                    onChange={handleToDateSelect}
                                    fullWidth
                                    inputRef={toDateRef}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ inputProps: { max: sysDate } }}
                                />
                            </Grid>
                        )}

                        {/* Year */}
                        {selectedReport === "monthlySaleProfit" && (
                            < Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Year</InputLabel>
                                    <Select
                                        label="Year"
                                        value={year}
                                        onChange={handleYearSelect}
                                        inputRef={yearRef}
                                    >
                                        {[...Array(20)].map((_, i) => {
                                            const year = new Date().getFullYear() - i;
                                            return (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {/* Customer Combo */}
                        {selectedReport === "customerWiseOutstanding" && (
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Customer</InputLabel>
                                    <Select
                                        label="Customer"
                                        value={selectedCustomer ? selectedCustomer.customerId : ''}
                                        onChange={handleCustomerSelect}
                                        inputRef={customerRef}
                                    >
                                        {customers.map((customer) => (
                                            <MenuItem key={customer.customerId} value={customer.customerId}>
                                                {customer.customerName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>

                    {/* 3rd Row: Search Button */}
                    <Grid container item justifyContent="center">
                        <Grid item xs={12} sm={6} md={3}>
                            <Button variant="contained" color="primary" sx={{ color: 'font.main' }} onClick={searchReport} fullWidth>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {/* Data Grid to Display Filtered Data */}
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    minHeight: 400
                }}
            >
                <DataGrid
                    rows={records}
                    columns={columns}
                    autoHeight // Adjusts height based on content
                    pageSizeOptions={[25, 50, 100]} // Options for page size
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 }, // Default to 25 records per page
                        },
                    }}
                    getRowId={(row) => row.invoiceNo || row.id}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            background: '#424242', // Set header background color (use any color)
                            color: 'font.main', // Set header text color
                        },
                        '& .MuiDataGrid-columnHeader': {
                            background: '#424242', // Ensure background color applies
                            color: 'font.main', // Ensure text color applies
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                        '@media (max-width: 600px)': {
                            '& .MuiDataGrid-columnHeaders': {
                                fontSize: '0.8rem',
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: '0.75rem',
                            },
                        },
                    }}
                />

                <Typography variant="h6" sx={{ mt: 5 }}>
                    {selectedReport === "sales" && (
                        <>Total Sales (Rs.): {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAmount)}</>
                    )}
                    {(selectedReport === "dailySaleProfitSummary" ||
                        selectedReport === "dailySaleProfitDetail" || selectedReport === "salesProfit" ||
                        selectedReport === "monthlySaleProfit") && (
                            <>Total Profit (Rs.): {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAmount)}</>
                        )}
                    {selectedReport === "return" && (
                        <>Total Return (Rs.): {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAmount)}</>
                    )}
                    {selectedReport === "dailyCashDetails" && (
                        <>Total Cash Income (Rs.): {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAmount)}</>
                    )}
                    {(selectedReport === "itemWiseSales" || selectedReport === "purchase") && (
                        <>Total (Rs.): {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAmount)}</>
                    )}
                    {(selectedReport === "customerWiseOutstanding") && (
                        <>Total Outstanding (Rs.): {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAmount)}</>
                    )}
                </Typography>

                <Typography variant="h6" sx={{ mt: 5 }}>
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            variant="contained" color="primary" sx={{ color: 'font.main' }}
                            onClick={printReport}
                            fullWidth
                        >
                            Print Report
                        </Button>
                        <iframe
                            ref={iframeRef}
                            style={{ display: 'none', width: '0', height: '0' }}
                            title="print-frame"
                        ></iframe>
                    </Grid>
                </Typography>
            </Paper>

            <CustomAlert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'P' ? "Do you want to print this report?" : ""}
                severity="warning"
                onConfirm={confirmType === 'P' ? printConfirmReport : null}
                isConfirmation
            />

        </Container >
    );
}

export default Reports;
