import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Divider,
  Grid,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ItemDetailsDialog from './ItemDetailsDialog';
import log from 'loglevel';

const ItemLocation = ({ open, onClose, item, branchId, user }) => {
  const [pricingDetails, setPricingDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  log.setLevel('info');

  // Memoized function to fetch pricing details
  const fetchPricingDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.API_BASE_URL}/stock/getitemlocations`, {
        params: { itemCode: item.itemCode },
      });
      setPricingDetails(response.data);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error fetching data:', error);
      }
      log.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [item.itemCode]);

  // Fetch pricing details when the dialog opens
  useEffect(() => {
    if (open) {
      fetchPricingDetails();
    }
  }, [open, fetchPricingDetails]);

  // Handle opening the ItemDetailsDialog when "View Batches" is clicked
  const handleViewBatches = (detail) => {
    setSelectedBatch(detail);
  };

  // Handle closing the ItemDetailsDialog
  const handleCloseItemDetails = () => {
    setSelectedBatch(null);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>Item Locations</DialogTitle>

      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Code:</strong> {item.itemCode}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Name:</strong> {item.itemName}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Company:</strong> {item.company}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Type:</strong> {item.itemType}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Unit:</strong> {item.unit}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Available Qty:</strong> {item.availableQty}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Reminding Qty:</strong> {item.remindingQty}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Other Details:</strong> {item.otherDetails}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Table>
          <TableHead sx={{ bgcolor: '#424242' }}>
            <TableRow>
              <TableCell style={{ color: 'white' }}><strong>Branch</strong></TableCell>
              <TableCell style={{ color: 'white' }}><strong>Quantity</strong></TableCell>
              <TableCell style={{ color: 'white' }}><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography>Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : pricingDetails.length > 0 ? (
              pricingDetails.map((detail, index) => (
                <TableRow key={index}>
                  <TableCell>{detail.branchName}</TableCell>
                  <TableCell>{detail.availableQty}</TableCell>
                  <TableCell>
                    {detail.branchId > 0 && (
                      <Button
                        variant="contained" color="primary" sx={{ color: 'font.main' }}
                        onClick={() => handleViewBatches(detail)}
                      >
                        View Batches
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography>No details found.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Close
        </Button>
      </DialogActions>

      {/* ItemDetailsDialog shown when a batch is selected */}
      {selectedBatch && (
        <ItemDetailsDialog
          open={!!selectedBatch}
          onClose={handleCloseItemDetails}
          item={selectedBatch}
          branchId={selectedBatch.branchId}
          user={user}
          isBranchStock={false}
        />
      )}
    </Dialog>
  );
};

export default ItemLocation;
