import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Drawer,
  Link,
  Collapse,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Receipt,
  Store,
  Payment as PaymentIcon,
  Replay,
  Inventory,
  Assessment,
  Person,
  ExitToApp,
  Inbox,
  LocalShipping,
  Group,
  MonetizationOn,
  AccountBalanceWallet,
  SwapHoriz,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Import the components to render
import Dashboard from './Dashboard';
import Invoice from '../Invoice/Invoice';
import Stock from '../Stock/Stock';
import StockBranch from '../Stock/StockBranch';
import StockAll from '../Stock/StockAll';
import Grn from '../GRN/Grn';
import TransferNew from '../Transfer/TransferNew';
import TransferApprove from '../Transfer/TransferApprove';
import Payment from '../Payment/Payment';
import Return from '../Return/Return';
import Item from '../Item/Item';
import Reports from '../Report/Reports';
import User from '../User/User';
import Supplier from '../Supplier/Supplier';
import Customer from '../Customer/Customer';
import IncomeExpense from '../IncomeExpense/IncomeExpense';
import CashDetails from '../CashDetails/CashDetails';
import BillPrint from '../Invoice/BillPrint';
import checkuserbranchnull from '../common/checkuserbranchnull';
import { useLocation } from 'react-router-dom';
import CustomAlert from '../common/CustomAlert';

// Create a theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#f50057',
    },
    icon: {
      main: '#ffffff',
    },
    font: {
      main: '#ffffff',
    },
  },
});

const Home = () => {
  const location = useLocation();
  const { user, branchId, branchName, sysDate, branchCount } = location.state || {};
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const [invoiceData, setInvoiceData] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Toggle submenu
  const toggleSubMenu = (menu) => {
    setOpenSubMenu((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  // Toggle drawer
  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  // Handle logout
  const handleLogout = () => {
    setConfirmOpen(true);
  };

  const handleConfirmLogout = () => {
    localStorage.removeItem('authToken');
    window.location.href = '/';
    setConfirmOpen(false);
  };

  // Function to render the selected component
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'dashboard':
        return <Dashboard user={user} branchId={branchId} />;
      case 'invoice':
        return <Invoice user={user} branchId={branchId} onInvoiceSaved={handleInvoiceSaved} />;
      case 'billprint':
        return <BillPrint invoiceData={invoiceData} />;
      case 'stock':
        return <Stock user={user} branchId={branchId} />;
      case 'branchstock':
        return <StockBranch user={user} branchId={branchId} />;
      case 'allstock':
        return <StockAll user={user} branchId={branchId} />;
      case 'grn':
        return <Grn user={user} />;
      case 'newtransfer':
        return <TransferNew user={user} branchId={branchId} />;
      case 'approvetransfer':
        return <TransferApprove user={user} branchId={branchId} />;
      case 'payment':
        return <Payment user={user} branchId={branchId} />;
      case 'return':
        return <Return user={user} branchId={branchId} />;
      case 'item':
        return <Item user={user} />;
      case 'reports':
        return <Reports user={user} branchId={branchId} sysDate={sysDate} />;
      case 'user':
        return <User user={user} branchCount={branchCount} />;
      case 'supplier':
        return <Supplier user={user} />;
      case 'customer':
        return <Customer user={user} />;
      case 'incomeexpense':
        return <IncomeExpense user={user} branchId={branchId} />;
      case 'cashdetails':
        return <CashDetails user={user} branchId={branchId} />;
      default:
        return <Dashboard />;
    }
  };

  const isUserTypeUser = user && user[0].User_Type === 'User';
  const hideGrnAndItem = branchId !== 1 && user && user[0].User_Type !== 'User';

  // New function to handle component change
  const handleComponentChange = (component, userData) => {
    setActiveComponent(component);
    // If needed, handle the userData here
  };

  const handleInvoiceSaved = (data) => {
    setInvoiceData(data); // Set the invoice data
    setActiveComponent('billprint'); // Change active component to BillPrint
  };

  const menu = (
    <Box sx={{ width: isMobile ? 250 : '100%' }} role="presentation">
      <List>
        {/* Dashboard Menu Item */}
        <ListItem
          onClick={() => {
            setActiveComponent('dashboard');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <HomeIcon sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="Dashboard" />
        </ListItem>

        {/* Invoice Menu Item */}
        <ListItem
          onClick={() => {
            setActiveComponent('invoice');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <Receipt sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="Invoice" />
        </ListItem>

        {(branchCount === 1) && (
          <>
            {/* Stock Menu Item */}
            <ListItem
              onClick={() => {
                setActiveComponent('stock');
                isMobile && toggleDrawer(false);
              }}
              sx={{
                '&:hover': {
                  cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                    color: 'icon.main',
                  },
                },
              }}
            >
              <Store sx={{ color: 'primary.main', marginRight: 1.5 }} />
              <ListItemText primary="Stock" />
            </ListItem>
          </>
        )}

        {(branchCount > 1) && (
          <>
            {/* Stock Menu with Submenu*/}
            <ListItem
              onClick={() => toggleSubMenu('stock')}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  bgcolor: 'primary.light', color: 'font.main',
                  '& .MuiSvgIcon-root': {
                    color: 'icon.main',
                  },
                },
                display: 'flex', // Ensures proper spacing
                justifyContent: 'space-between', // Separates text and arrow
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Store sx={{ color: 'primary.main', marginRight: 1.5 }} />
                <ListItemText primary="Stock" />
              </Box>
              <Box sx={{ marginLeft: 'auto', flexShrink: 0 }}>
                {openSubMenu.stock ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </ListItem>
            <Collapse in={openSubMenu.stock} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  onClick={() => {
                    setActiveComponent('branchstock');
                    isMobile && toggleDrawer(false);
                  }}
                  sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', } }}
                >
                  <ListItemText primary="Branch Stock" />
                </ListItem>
                <ListItem
                  onClick={() => {
                    setActiveComponent('allstock');
                    isMobile && toggleDrawer(false);
                  }}
                  sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', } }}
                >
                  <ListItemText primary="All Stock" />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}

        {/* Conditionally render GRN and Stock Transfer menus */}
        {!isUserTypeUser && !hideGrnAndItem && (
          <>
            {/* GRN Menu Item */}
            <ListItem
              onClick={() => {
                setActiveComponent('grn');
                isMobile && toggleDrawer(false);
              }}
              sx={{
                '&:hover': {
                  cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                    color: 'icon.main',
                  },
                },
              }}
            >
              <Inbox sx={{ color: 'primary.main', marginRight: 1.5 }} />
              <ListItemText primary="GRN" />
            </ListItem>
          </>
        )}

        {(branchCount > 1 && !isUserTypeUser) && (
          <>
            {/* Transfer Menu with Submenu */}
            <ListItem
              onClick={() => toggleSubMenu('transfer')}
              sx={{
                '&:hover': {
                  cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                    color: 'icon.main',
                  },
                },
                display: 'flex', // Ensures proper spacing
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SwapHoriz sx={{ color: 'primary.main', marginRight: 1.5 }} />
                <ListItemText primary="Transfer" />
              </Box>
              <Box sx={{ marginLeft: 'auto', flexShrink: 0 }}>
                {openSubMenu.transfer ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </ListItem>
            <Collapse in={openSubMenu.transfer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  onClick={() => {
                    setActiveComponent('newtransfer');
                    isMobile && toggleDrawer(false);
                  }}
                  sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', } }}
                >
                  <ListItemText primary="New Transfer" />
                </ListItem>
                <ListItem
                  onClick={() => {
                    setActiveComponent('approvetransfer');
                    isMobile && toggleDrawer(false);
                  }}
                  sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', } }}
                >
                  <ListItemText primary="Approve Transfer" />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}

        {/* Payment Menu Item */}
        <ListItem
          onClick={() => {
            setActiveComponent('payment');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <PaymentIcon sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="Payment" />
        </ListItem>

        {/* Return Menu Item */}
        <ListItem
          onClick={() => {
            setActiveComponent('return');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <Replay sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="Return" />
        </ListItem>

        {!isUserTypeUser && !hideGrnAndItem && (
          <>
            {/* Item Menu Item */}
            <ListItem
              onClick={() => {
                handleComponentChange('item');
                isMobile && toggleDrawer(false);
              }}
              sx={{
                '&:hover': {
                  cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                    color: 'icon.main',
                  },
                },
              }}
            >
              <Inventory sx={{ color: 'primary.main', marginRight: 1.5 }} />
              <ListItemText primary="Item" />
            </ListItem>
          </>
        )}

        {!isUserTypeUser && (
          <>
            {/* Reports Menu Item */}
            <ListItem
              onClick={() => {
                setActiveComponent('reports');
                isMobile && toggleDrawer(false);
              }}
              sx={{
                '&:hover': {
                  cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                    color: 'icon.main',
                  },
                },
              }}
            >
              <Assessment sx={{ color: 'primary.main', marginRight: 1.5 }} />
              <ListItemText primary="Reports" />
            </ListItem>
          </>
        )}

        <ListItem
          onClick={() => {
            setActiveComponent('user');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <Person sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="User" />
        </ListItem>

        {!isUserTypeUser && !hideGrnAndItem && (
          <>
            <ListItem
              onClick={() => {
                handleComponentChange('supplier', user);
                isMobile && toggleDrawer(false);
              }}
              sx={{
                '&:hover': {
                  cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                    color: 'icon.main',
                  },
                },
              }}
            >
              <LocalShipping sx={{ color: 'primary.main', marginRight: 1.5 }} />
              <ListItemText primary="Supplier" />
            </ListItem>
          </>
        )}

        <ListItem
          onClick={() => {
            setActiveComponent('customer');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <Group sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="Customer" />
        </ListItem>

        <ListItem
          onClick={() => {
            setActiveComponent('incomeexpense');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <MonetizationOn sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="Income/Expense" />
        </ListItem>

        <ListItem
          onClick={() => {
            setActiveComponent('cashdetails');
            isMobile && toggleDrawer(false);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer', bgcolor: 'primary.light', color: 'font.main', '& .MuiSvgIcon-root': {
                color: 'icon.main',
              },
            },
          }}
        >
          <AccountBalanceWallet sx={{ color: 'primary.main', marginRight: 1.5 }} />
          <ListItemText primary="Cash Details" />
        </ListItem>

        <Box sx={{ mt: 5, textAlign: 'center' }}>
          <Button
            variant="contained" color="primary"
            onClick={handleLogout}
            startIcon={<ExitToApp />}
            sx={{
              color: 'font.main',
              width: '100%',
              '&:hover': { cursor: 'pointer', bgcolor: 'primary.dark' },
            }}
          >
            Logout
          </Button>
        </Box>
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* AppBar with the company logo and user info */}
        <AppBar position="static" sx={{ bgcolor: 'primary.main', boxShadow: 3 }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => toggleDrawer(true)}
                sx={{
                  marginRight: 2,
                  '&:hover': {
                    bgcolor: 'primary.dark', // Darken on hover
                    transition: 'background-color 0.3s ease',
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              src="/Logo_Small.png"
              alt="Company Logo"
              style={{
                height: '50px', // Make logo more prominent
                objectFit: 'contain',
                marginRight: '20px',
              }}
            />
            <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 700, fontSize: '1.5rem', color: 'white' }}>
              {branchName || 'Your Branch Name'}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  fontSize: '1rem',
                  color: 'white',
                  marginBottom: 0.5, // Small spacing between user and date
                }}
              >
                {user ? `User: ${user[0].User_Name}` : 'User'}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  fontSize: '0.9rem',
                  fontWeight: 400,
                }}
              >
                {sysDate ? `Date: ${sysDate}` : 'Date'}
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Drawer for Mobile View */}
        <Drawer anchor="left" open={drawerOpen} onClose={() => toggleDrawer(false)}>
          {menu}
        </Drawer>

        <Grid container sx={{ mt: 2 }} spacing={2}>
          {/* Sidebar for Desktop View */}
          {!isMobile && (
            <Grid item xs={2}>
              <Box sx={{
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                margin: 0,
              }}>{menu}</Box>
            </Grid>
          )}

          {/* Main Content */}
          <Grid item xs={12} md={10}>
            <Box sx={{ p: 2 }}>{renderActiveComponent()}</Box>
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 2, mt: 4, textAlign: 'center' }}>
          <Typography variant="body2">
            Version {process.env.REACT_APP_VERSION} | Copyright © {' '}
            <Link
              color="inherit"
              target="_blank"
              href="https://velocecode.lk"
              style={{ textDecoration: 'none' }}
            >
              VeloceCode
            </Link>{' '}{new Date().getFullYear()}. All Rights Reserved.
          </Typography>
        </Box>
      </div>

      {/* Confirmation Alert */}
      <CustomAlert
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        message={"Do you want to log out of the system?"}
        severity="warning"
        onConfirm={handleConfirmLogout}
        isConfirmation
      />

    </ThemeProvider>
  );
};

export default checkuserbranchnull(Home);