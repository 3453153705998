import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const UserAdd = ({ visible, setVisible, user, refreshGrid }) => {
    const [formData, setFormData] = useState({
        nic: '',
        firstName: '',
        lastName: '',
        gender: '',
        mobileNo: '',
        homeNo: '',
        userType: '',
        branch: '',
        userName: '',
        password: '',
        confirmPassword: '',
        globalUser: false,
        status: true,
    });

    const [userTypes, setUserTypes] = useState([]);
    const [branches, setBranches] = useState([]);
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);
    const [successUpdate, setSuccessUpdate] = useState(false);

    // Create refs for input fields
    const nicRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const genderRef = useRef(null);
    const mobileNoRef = useRef(null);
    const homeNoRef = useRef(null);
    const userTypeRef = useRef(null);
    const branchRef = useRef(null);
    const userNameRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const fetchUserTypes = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/user/getusertypes`);
            setUserTypes(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving user types:', error);
            }
            log.error('Error fetching user types:', error);
        }
    }, []);

    const fetchBranches = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/branch/getbranches`);
            setBranches(response.data);

        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving branches:', error);
            }
            log.error('Error fetching branches:', error);
        }
    }, []);

    useEffect(() => {
        // Automatically set branch if there is only one branch
        if (branches.length === 1) {
            setFormData(prevState => ({
                ...prevState,
                branch: branches[0].branchId,
            }));
        }
    }, [branches]);

    useEffect(() => {
        fetchUserTypes();
        fetchBranches();
    }, [fetchUserTypes, fetchBranches]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const validateNIC = (nic) => {
        const oldNicPattern = /^[0-9]{9}[vVxX]$/;  // Old NIC format: 9 digits + V/X
        const newNicPattern = /^[0-9]{12}$/;       // New NIC format: 12 digits

        return oldNicPattern.test(nic) || newNicPattern.test(nic);
    };

    const handleAddUser = async () => {

        const { nic, firstName, lastName, gender, mobileNo, homeNo, userType, branch, userName, password, confirmPassword } = formData;

        if (!nic) {
            setAlertMessage('Please enter the NIC.');
            setAlertSeverity('error');
            setAlertOpen(true);
            nicRef.current.focus();
            return;
        }
        if (!validateNIC(nic)) {
            setAlertMessage('Please enter a valid NIC.');
            setAlertSeverity('error');
            setAlertOpen(true);
            nicRef.current.focus();
            return;
        }
        if (!firstName) {
            setAlertMessage('Please enter the First Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            firstNameRef.current.focus();
            return;
        }
        if (!lastName) {
            setAlertMessage('Please enter the Last Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            lastNameRef.current.focus();
            return;
        }
        if (!gender) {
            setAlertMessage('Please select a Gender.');
            setAlertSeverity('error');
            setAlertOpen(true);
            genderRef.current.focus();
            return;
        }
        if (!mobileNo) {
            setAlertMessage('Please enter the Mobile No.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }
        if (mobileNo.length < 10) {
            setAlertMessage('Please enter a valid mobile number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }
        if (homeNo.length > 0 && homeNo.length < 10) {
            setAlertMessage('Please enter a valid home number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            homeNoRef.current.focus();
            return;
        }
        if (!userType) {
            setAlertMessage('Please select a User Type.');
            setAlertSeverity('error');
            setAlertOpen(true);
            userTypeRef.current.focus();
            return;
        }
        if (!branch) {
            setAlertMessage('Please select a Branch.');
            setAlertSeverity('error');
            setAlertOpen(true);
            branchRef.current.focus();
            return;
        }
        if (!userName) {
            setAlertMessage('Please enter the User Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            userNameRef.current.focus();
            return;
        }
        if (!password) {
            setAlertMessage('Please enter the Password.');
            setAlertSeverity('error');
            setAlertOpen(true);
            passwordRef.current.focus();
            return;
        }
        if (!confirmPassword) {
            setAlertMessage('Please enter the Confirm Password.');
            setAlertSeverity('error');
            setAlertOpen(true);
            confirmPasswordRef.current.focus();
            return;
        }
        if (password !== confirmPassword) {
            setAlertMessage('The password and confirmation password do not match.');
            setAlertSeverity('error');
            setAlertOpen(true);
            confirmPasswordRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("S");

    };

    const handleConfirmAddUser = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/user/saveuser`, { data: formData, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);
            setSuccessUpdate(response.data.success);

            if (response.data.success) {
                refreshGrid();
                setFormData({
                    nic: '',
                    firstName: '',
                    lastName: '',
                    gender: '',
                    mobileNo: '',
                    homeNo: '',
                    userType: '',
                    branch: '',
                    userName: '',
                    password: '',
                    confirmPassword: '',
                    globalUser: false,
                    status: true,
                });
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);

            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving user:', error);
            }
            log.error('Error saving user:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
        if (successUpdate) {
            setVisible();
        }
    };

    return (
        <Dialog open={visible} onClose={() => setVisible(false)}>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                <Typography variant="h6" component="span">Add New User</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <TextField
                            inputRef={nicRef}
                            name="nic"
                            label="NIC"
                            value={formData.nic.trim()}
                            onChange={handleChange}
                            fullWidth
                            required
                            inputProps={{ maxLength: 12 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <TextField
                            inputRef={firstNameRef}
                            name="firstName"
                            label="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={lastNameRef}
                            name="lastName"
                            label="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel>Gender</InputLabel>
                            <Select
                                inputRef={genderRef}
                                name="gender"
                                label="Gender"
                                value={formData.gender || ''} // Ensure controlled value
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={mobileNoRef}
                            name="mobileNo"
                            label="Mobile No"
                            value={formData.mobileNo}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                    handleChange(e);
                                }
                            }}
                            fullWidth
                            required
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={homeNoRef}
                            name="homeNo"
                            label="Home No"
                            value={formData.homeNo}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                    handleChange(e);
                                }
                            }}
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel>User Type</InputLabel>
                            <Select
                                inputRef={userTypeRef}
                                name="userType"
                                label="User Type"
                                value={formData.userType || ''} // Ensure controlled value
                                onChange={handleChange}
                                displayEmpty
                            >
                                {userTypes.map((type) => (
                                    <MenuItem key={type.userTypeId} value={type.userTypeId}>
                                        {type.userType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {branches.length > 1 && (
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Branch</InputLabel>
                                <Select
                                    inputRef={branchRef}
                                    name="branch"
                                    label="Branch"
                                    value={formData.branch || ''} // Ensure controlled value
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    {branches.map((branch) => (
                                        <MenuItem key={branch.branchId} value={branch.branchId}>
                                            {branch.branchName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            inputRef={userNameRef}
                            name="userName"
                            label="User Name"
                            value={formData.userName}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={passwordRef}
                            name="password"
                            label="Password"
                            type="password"
                            value={formData.password.trim()}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={confirmPasswordRef}
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            value={formData.confirmPassword.trim()}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    {branches.length > 1 && (
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.globalUser}
                                        onChange={handleChange}
                                        name="globalUser"
                                    />
                                }
                                label="Global User"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.status}
                                    onChange={handleChange}
                                    name="status"
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setVisible(false)} color="primary" variant='outlined'>
                    Cancel
                </Button>
                <Button onClick={handleAddUser} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                    Save
                </Button>
            </DialogActions>

            {/* Custom Alert */}
            <CustomAlert
                open={alertOpen}
                onClose={handleAlertClose}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'S' ? "Do you want to add this user?" : ""}
                severity="warning"
                onConfirm={confirmType === 'S' ? handleConfirmAddUser : null}
                isConfirmation
            />

        </Dialog>
    );
};

export default UserAdd;
