import React, { useEffect, useState, useRef } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, InputLabel, FormControl,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, ThemeProvider, Container, AppBar,
    Toolbar, Typography, Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ReactPaginate from 'react-paginate';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../common/CustomAlert';
import EditIcon from '@mui/icons-material/Edit';
import log from 'loglevel';

const Item = ({ user }) => {
    const theme = useTheme();
    const [items, setItems] = useState([]);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    const [units, setUnits] = useState([]);
    const [newCompany, setNewCompany] = useState('');
    const [newItemType, setNewItemType] = useState('');
    const [newUnit, setNewUnit] = useState('');
    const [openAddCompany, setOpenAddCompany] = useState(false);
    const [openAddItemType, setOpenAddItemType] = useState(false);
    const [openAddUnit, setOpenAddUnit] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    // Create refs for input fields
    const itemCodeRef = useRef(null);
    const itemNameRef = useRef(null);
    const companyRef = useRef(null);
    const itemTypeRef = useRef(null);
    const unitRef = useRef(null);
    const remindingQtyRef = useRef(null);
    const companyNameRef = useRef(null);
    const itemTypeNameRef = useRef(null);
    const unitNameRef = useRef(null);

    const [formData, setFormData] = useState({
        itemCode: '',
        itemName: '',
        company: '',
        itemType: '',
        unit: '',
        remindingQty: 0,
        description: '',
    });

    const [updateFormData, setUpdateFormData] = useState({
        itemCode: '',
        itemName: '',
        // companyId: '',
        company: '',
        itemType: '',
        unit: '',
        remindingQty: 0,
        description: '',
    });

    useEffect(() => {
        if (visible && itemCodeRef.current) {
            itemCodeRef.current.focus();
        }
    }, [visible]);

    useEffect(() => {
        if (updateVisible && itemNameRef.current) {
            itemNameRef.current.focus();
        }
    }, [updateVisible]);

    useEffect(() => {
        fetchItems();
        fetchCompanies();
        fetchItemTypes();
        fetchUnits();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/item/getallitems`);
            setItems(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching items:', error);
            }
            log.error('Error fetching items:', error);
        }
    };

    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/company/getcompanies`);
            setCompanies(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching companies:', error);
            }
            log.error('Error fetching companies:', error);
        }
    };

    const fetchItemTypes = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/itemtype/getitemtypes`);
            setItemTypes(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching item types:', error);
            }
            log.error('Error fetching item types:', error);
        }
    };

    const fetchUnits = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/unit/getunits`);
            setUnits(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching units:', error);
            }
            log.error('Error fetching units:', error);
        }
    };

    const filteredItems = items.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.itemCode.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setPageCount(Math.ceil(filteredItems.length / rowsPerPage));
    }, [filteredItems]);

    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    const paginatedItems = filteredItems.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAddItem = () => {
        const { itemCode, itemName, company, itemType, unit, remindingQty } = formData;

        if (!itemCode) {
            setAlertMessage('Please enter the Item Code.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemCodeRef.current.focus();
            return;
        }

        if (!itemName) {
            setAlertMessage('Please enter the Item Name');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemNameRef.current.focus();
            return;
        }

        if (!company) {
            setAlertMessage('Please select a Company.');
            setAlertSeverity('error');
            setAlertOpen(true);
            companyRef.current.focus();
            return;
        }

        if (!itemType) {
            setAlertMessage('Please select an Item Type.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemTypeRef.current.focus();
            return;
        }

        if (!unit) {
            setAlertMessage('Please select an Unit.');
            setAlertSeverity('error');
            setAlertOpen(true);
            unitRef.current.focus();
            return;
        }

        if (remindingQty <= 0) {
            setAlertMessage('Please enter a reminding quantity greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            remindingQtyRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("NI");
    };

    // Confirm adding the item
    const handleConfirmAddItem = async () => {
        try {
            const response = await axios.post(`${config.API_BASE_URL}/item/saveitem`, {
                newItem: formData,
                sysUser: user[0].User_Name
            });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setVisible(false);
                setFormData({
                    itemCode: '',
                    itemName: '',
                    company: '',
                    itemType: '',
                    unit: '',
                    remindingQty: 0,
                    description: '',
                });
                fetchItems();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleUpdateItem = async () => {
        const { itemName, company, itemType, unit, remindingQty } = updateFormData;

        if (!itemName) {
            setAlertMessage('Please enter the Item Name');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemNameRef.current.focus();
            return;
        }

        if (!company) {
            setAlertMessage('Please select a Company.');
            setAlertSeverity('error');
            setAlertOpen(true);
            companyRef.current.focus();
            return;
        }

        if (!itemType) {
            setAlertMessage('Please select an Item Type.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemTypeRef.current.focus();
            return;
        }

        if (!unit) {
            setAlertMessage('Please select an Unit.');
            setAlertSeverity('error');
            setAlertOpen(true);
            unitRef.current.focus();
            return;
        }

        if (remindingQty <= 0) {
            setAlertMessage('Please enter a reminding quantity greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            remindingQtyRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("UI");
    };

    const handleConfirmUpdateItem = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/item/updateitem`, {
                updatedItem: updateFormData,
                sysUser: user[0].User_Name
            });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setUpdateVisible(false);
                fetchItems();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleEditClick = (item) => {
        setUpdateFormData({
            itemCode: item.itemCode,
            itemName: item.itemName,
            company: item.companyId,
            itemType: item.itemTypeId,
            unit: item.unitId,
            remindingQty: item.remindingQty,
            description: item.description,
        });
        setUpdateVisible(true);
    };

    const handleAddNewCompany = async () => {
        if (!newCompany) {
            setAlertMessage('Please enter the Company Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            companyNameRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("NC");
    };

    const handleConfirmAddNewCompany = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/company/savecompany`, { companyName: newCompany, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setNewCompany('');
                setOpenAddCompany(false);
                fetchCompanies();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleAddNewItemType = async () => {
        if (!newItemType) {
            setAlertMessage('Please enter the Item Type.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemTypeNameRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("NT");
    };

    const handleConfirmAddNewItemType = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/itemtype/saveitemtype`, { itemTypeDesc: newItemType, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setNewItemType('');
                setOpenAddItemType(false);
                fetchItemTypes();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleAddNewUnit = async () => {
        if (!newUnit) {
            setAlertMessage('Please enter the Unit.');
            setAlertSeverity('error');
            setAlertOpen(true);
            unitNameRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("NU");
    };

    const handleConfirmAddNewUnit = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/unit/saveunit`, { unitName: newUnit, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setNewUnit('');
                setOpenAddUnit(false);
                fetchUnits();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
        } finally {
            setConfirmOpen(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            Items Management
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            variant="contained" color="primary" sx={{ color: 'font.main' }}
                            fullWidth
                            onClick={() => setVisible(true)}
                        >
                            Add New Item
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Item Code</TableCell>
                                <TableCell style={{ color: 'white' }}>Item Name</TableCell>
                                <TableCell style={{ color: 'white' }}>Company</TableCell>
                                <TableCell style={{ color: 'white' }}>Item Type</TableCell>
                                <TableCell style={{ color: 'white' }}>Unit</TableCell>
                                <TableCell style={{ color: 'white' }}>Reminding Qty</TableCell>
                                <TableCell style={{ color: 'white' }}>Description</TableCell>
                                <TableCell style={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedItems.map((item) => (
                                <TableRow key={item.itemCode} hover>
                                    <TableCell>{item.itemCode}</TableCell>
                                    <TableCell>{item.itemName}</TableCell>
                                    <TableCell>{item.companyName}</TableCell>
                                    <TableCell>{item.itemTypeDesc}</TableCell>
                                    <TableCell>{item.unitName}</TableCell>
                                    <TableCell>{item.remindingQty}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit Item Details">
                                            <IconButton color="primary" onClick={() => handleEditClick(item)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                {pageCount > 0 && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        style={{ marginTop: '20px' }}
                    />
                )}

                {/* Dialogs */}
                <Dialog open={visible} onClose={() => setVisible(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Add Item Details</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                <TextField
                                    inputRef={itemCodeRef}
                                    name="itemCode"
                                    label="Item Code"
                                    value={formData.itemCode}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                <TextField
                                    inputRef={itemNameRef}
                                    name="itemName"
                                    label="Item Name"
                                    value={formData.itemName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Company</InputLabel>
                                    <Select
                                        inputRef={companyRef}
                                        name="company"
                                        label="Company"
                                        value={formData.company}
                                        onChange={handleChange}
                                    >
                                        {companies.map((company) => (
                                            <MenuItem key={company.companyId} value={company.companyId}>
                                                {company.companyName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => setOpenAddCompany(true)} color="primary">
                                        Add New Company
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Item Type</InputLabel>
                                    <Select
                                        inputRef={itemTypeRef}
                                        name="itemType"
                                        label="Item Type"
                                        value={formData.itemType}
                                        onChange={handleChange}
                                    >
                                        {itemTypes.map((itemType) => (
                                            <MenuItem key={itemType.itemTypeId} value={itemType.itemTypeId}>
                                                {itemType.itemTypeDesc}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => setOpenAddItemType(true)} color="primary">
                                        Add New Item Type
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Unit</InputLabel>
                                    <Select
                                        inputRef={unitRef}
                                        name="unit"
                                        label="Unit"
                                        value={formData.unit}
                                        onChange={handleChange}
                                    >
                                        {units.map((unit) => (
                                            <MenuItem key={unit.unitId} value={unit.unitId}>
                                                {unit.unitName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => setOpenAddUnit(true)} color="primary">
                                        Add New Unit
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={remindingQtyRef}
                                    name="remindingQty"
                                    label="Reminding Qty"
                                    type="text"
                                    value={formData.remindingQty}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            handleChange(e); // update value
                                        }
                                    }}
                                    onBlur={() => {
                                        if (!formData.remindingQty) {
                                            handleChange({ target: { name: 'remindingQty', value: 0 } });
                                        }
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="description"
                                    label="Description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setVisible(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleAddItem} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add Company Dialog */}
                <Dialog open={openAddCompany} onClose={() => setOpenAddCompany(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Add New Company</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField sx={{ mt: 2 }}
                            inputRef={companyNameRef}
                            label="Company Name"
                            value={newCompany}
                            onChange={(e) => setNewCompany(e.target.value)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAddCompany(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleAddNewCompany} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add Item Type Dialog */}
                <Dialog open={openAddItemType} onClose={() => setOpenAddItemType(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Add New Item Type</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField sx={{ mt: 2 }}
                            inputRef={itemTypeNameRef}
                            label="Item Type Name"
                            value={newItemType}
                            onChange={(e) => setNewItemType(e.target.value)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAddItemType(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleAddNewItemType} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add Unit Dialog */}
                <Dialog open={openAddUnit} onClose={() => setOpenAddUnit(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Add New Unit</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField sx={{ mt: 2 }}
                            inputRef={unitNameRef}
                            label="Unit Name"
                            value={newUnit}
                            onChange={(e) => setNewUnit(e.target.value)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAddUnit(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleAddNewUnit} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Update Item Dialog */}
                <Dialog open={updateVisible} onClose={() => setUpdateVisible(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Update Item Details</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                <TextField
                                    label="Item Code"
                                    value={updateFormData.itemCode} // Assuming itemCode is part of updateFormData
                                    disabled // This makes the field disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                <TextField
                                    inputRef={itemNameRef}
                                    name="itemName"
                                    label="Item Name"
                                    value={updateFormData.itemName}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, itemName: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Company</InputLabel>
                                    <Select
                                        inputRef={companyRef}
                                        name="company"
                                        label="Company"
                                        value={updateFormData.company}
                                        onChange={(e) => setUpdateFormData({ ...updateFormData, company: e.target.value })}
                                    >
                                        {companies.map((company) => (
                                            <MenuItem key={company.companyId} value={company.companyId}>
                                                {company.companyName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => setOpenAddCompany(true)} color="primary">
                                        Add New Company
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Item Type</InputLabel>
                                    <Select
                                        inputRef={itemTypeRef}
                                        name="itemType"
                                        label="Item Type"
                                        value={updateFormData.itemType}
                                        onChange={(e) => setUpdateFormData({ ...updateFormData, itemType: e.target.value })}
                                    >
                                        {itemTypes.map((itemType) => (
                                            <MenuItem key={itemType.itemTypeId} value={itemType.itemTypeId}>
                                                {itemType.itemTypeDesc}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => setOpenAddItemType(true)} color="primary">
                                        Add New Item Type
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Unit</InputLabel>
                                    <Select
                                        inputRef={unitRef}
                                        name="unit"
                                        label="Unit"
                                        value={updateFormData.unit}
                                        onChange={(e) => setUpdateFormData({ ...updateFormData, unit: e.target.value })}
                                    >
                                        {units.map((unit) => (
                                            <MenuItem key={unit.unitId} value={unit.unitId}>
                                                {unit.unitName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => setOpenAddUnit(true)} color="primary">
                                        Add New Unit
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={remindingQtyRef}
                                    name="remindingQty"
                                    label="Reminding Qty"
                                    type="text"
                                    value={updateFormData.remindingQty}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            setUpdateFormData({ ...updateFormData, remindingQty: value });
                                        }
                                    }}
                                    onBlur={() => {
                                        if (!updateFormData.remindingQty) {
                                            setUpdateFormData({ ...updateFormData, remindingQty: 0 });
                                        }
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="description"
                                    label="Description"
                                    value={updateFormData.description}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, description: e.target.value })}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setUpdateVisible(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleUpdateItem} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Custom Alert */}
                <CustomAlert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                {/* Confirmation Alert */}
                <CustomAlert
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}

                    message={confirmType === 'NI' ? "Do you want to add this item?" :
                        confirmType === 'UI' ? "Do you want to update this item?" :
                            confirmType === 'NC' ? "Do you want to add this company?" :
                                confirmType === 'NT' ? "Do you want to add this item type?" :
                                    confirmType === 'NU' ? "Do you want to add this unit?" : ""}

                    severity="warning"
                    //onConfirm={handleConfirmAddItem}

                    onConfirm={confirmType === 'NI' ? handleConfirmAddItem :
                        confirmType === 'UI' ? handleConfirmUpdateItem :
                            confirmType === 'NC' ? handleConfirmAddNewCompany :
                                confirmType === 'NT' ? handleConfirmAddNewItemType :
                                    confirmType === 'NU' ? handleConfirmAddNewUnit : null}

                    isConfirmation
                />
            </Container>
        </ThemeProvider>
    );
};

export default Item;
