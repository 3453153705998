import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  TextField,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  Grid,
  useTheme,
  Tooltip,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { ListAlt } from '@mui/icons-material';
import ItemDetailsDialog from './ItemDetailsDialog'; // Import the dialog component
import './Stock.css';
import config from '../../services/config';
import log from 'loglevel';

const StockBranch = ({ user, branchId }) => {
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const rowsPerPage = 10;
  log.setLevel('info');

  // Memoized function to fetch data
  const fetchItems = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/stock/getbranchstock`, { params: { branchId } });
      setItems(response.data);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error fetching data:', error);
      }
      log.error('Error fetching data:', error);
    }
  }, [branchId]);

  // Fetch data on component mount
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  // Filter items based on search input
  const filteredItems = items.filter(item =>
    item.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.itemCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.itemType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Update page count after filtering
  useEffect(() => {
    setPageCount(Math.ceil(filteredItems.length / rowsPerPage));
  }, [filteredItems]);

  // Reset current page when search term changes
  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  // Get items for the current page only
  const paginatedItems = filteredItems.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  // Function to get color based on item status
  const getStatusColor = (status) => {
    switch (status) {
      case 'OK':
        return { color: 'green' };
      case 'Low Qty':
        return { color: 'orange' };
      case 'Not Available':
        return { color: 'red' };
      default:
        return { color: 'black' };
    }
  };

  // Function to format numbers with commas
  const formatNumber = (number) => {
    return new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  };

  // Handle opening the dialog
  const handleViewBatches = (item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  // Handle closing the dialog and refreshing data
  const handleCloseDialog = async () => {
    setDialogOpen(false);
    setSelectedItem(null);
    await fetchItems(); // Refresh items after closing the dialog
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
          <Toolbar>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
              Branch Stock
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Total Stock Information */}
        <Card variant="outlined" sx={{ mb: 2, border: `1px solid ${theme.palette.primary.main}` }}>
          <CardContent>
            <Grid container spacing={2}>
              {user[0].User_Type !== "User" && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    Total Stock Amount: Rs. {formatNumber(filteredItems.reduce((acc, item) => acc + item.totalAmount, 0).toFixed(2))}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  Total Items: {filteredItems.length}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Search Field */}
        <TextField
          variant="outlined"
          label="Search"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'primary.main',
              },
              '&:hover fieldset': {
                borderColor: 'primary.main',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
              },
            },
          }}
        />

        {/* Stock Table */}
        <Paper elevation={3}>
          <Box sx={{ overflowX: 'auto' }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ bgcolor: '#424242' }}>
                <TableRow>
                  <TableCell sx={{ color: 'white' }}>Item Code</TableCell>
                  <TableCell sx={{ color: 'white' }}>Item Name</TableCell>
                  <TableCell sx={{ color: 'white' }}>Company</TableCell>
                  <TableCell sx={{ color: 'white' }}>Item Type</TableCell>
                  <TableCell sx={{ color: 'white' }}>Unit</TableCell>
                  <TableCell sx={{ color: 'white' }}>Available Qty</TableCell>
                  {user[0].User_Type !== "User" && (
                    <TableCell sx={{ color: 'white' }}>Total Amount (Rs.)</TableCell>
                  )}
                  <TableCell sx={{ color: 'white' }}>Reminding Qty</TableCell>
                  <TableCell sx={{ color: 'white' }}>Other Details</TableCell>
                  <TableCell sx={{ color: 'white' }}>Status</TableCell>
                  <TableCell sx={{ color: 'white', width: '120px' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedItems.map((item) => (
                  <TableRow key={item.itemCode}>
                    <TableCell>{item.itemCode}</TableCell>
                    <TableCell>{item.itemName}</TableCell>
                    <TableCell>{item.company}</TableCell>
                    <TableCell>{item.itemType}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell>{item.availableQty}</TableCell>
                    {user[0].User_Type !== "User" && (
                      <TableCell align="right">{formatNumber(item.totalAmount.toFixed(2))}</TableCell>
                    )}
                    <TableCell>{item.remindingQty}</TableCell>
                    <TableCell>{item.otherDetails}</TableCell>
                    <TableCell sx={getStatusColor(item.status)}>{item.status}</TableCell>
                    <TableCell>
                      {item.availableQty > 0 && (
                        <Tooltip title={item.availableQty === 0 ? "No available stock to view batches" : "View Batches"}>
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                if (item.availableQty > 0) {
                                  handleViewBatches(item); // Open the dialog
                                }
                              }}
                              disabled={item.availableQty === 0}
                            >
                              <ListAlt />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Paper>

        {/* Pagination */}
        {pageCount > 0 && (
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
        )}

        {/* Item Details Dialog */}
        {selectedItem && (
          <ItemDetailsDialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            item={selectedItem}
            branchId={branchId}
            user={user}
            isBranchStock={true}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default StockBranch;
