import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, InputLabel, FormControl,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, ThemeProvider, Container, AppBar,
    Toolbar, Typography, Grid,
    InputAdornment,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ReactPaginate from 'react-paginate';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const IncomeExpense = ({ user, branchId }) => {
    const theme = useTheme();
    const [incomeExpenses, setIncomeExpense] = useState([]);
    const [visible, setVisible] = useState(false);
    const [descriptions, setDescriptions] = useState([]);
    const [newDescription, setNewDescription] = useState('');
    const [newType, setNewType] = useState('');
    const [openAddDescription, setOpenAddDescription] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    // Create refs for input fields
    const typeRef = useRef(null);
    const descriptionRef = useRef(null);
    const amountRef = useRef(null);
    const addDescTypeRef = useRef(null);
    const addDescRef = useRef(null);

    const [formData, setFormData] = useState({
        type: '',
        description: '',
        amount: (0).toFixed(2),
        comment: ''
    });

    useEffect(() => {
        if (visible && typeRef.current) {
            typeRef.current.focus();
        }
    }, [visible]);

    const fetchIncomeExpenses = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/incomeexpense/getincomeexpense`, { params: { branchId } });
            setIncomeExpense(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching items:', error);
            }
            log.error('Error fetching items:', error);
        }
    }, [branchId]);

    const fetchDescriptions = async (type) => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/incomeexpense/getincomeexpensedesc`, { params: { type } });
            setDescriptions(response.data);

        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching description:', error);
            }
            log.error('Error fetching description:', error);
        }
    };

    useEffect(() => {
        fetchIncomeExpenses();
    }, [fetchIncomeExpenses]);

    const filteredIncomeExpenses = incomeExpenses.filter(incomeExpense =>
        incomeExpense.date.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setPageCount(Math.ceil(filteredIncomeExpenses.length / rowsPerPage));
    }, [filteredIncomeExpenses]);

    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    const paginatedIncomeExpenses = filteredIncomeExpenses.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));

        if (name === 'type') {
            await fetchDescriptions(value);
        }
    };

    const handleAddIncomeExpense = () => {
        const { type, description, amount } = formData;

        if (!type) {
            setAlertMessage('Please select a Type.');
            setAlertSeverity('error');
            setAlertOpen(true);
            typeRef.current.focus();
            return;
        }

        if (!description) {
            setAlertMessage('Please select a Description.');
            setAlertSeverity('error');
            setAlertOpen(true);
            descriptionRef.current.focus();
            return;
        }

        if (parseFloat(amount) <= 0) {
            setAlertMessage('Please enter a amount greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            amountRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("NI");
    };

    const handleConfirmAddIncomeExpense = async () => {
        try {

            console.log("formData ", formData);
            const response = await axios.post(`${config.API_BASE_URL}/incomeexpense/saveincomeexpense`, {
                formData: formData,
                branchId: branchId,
                loginId: user[0].Login_Id,
            });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setVisible(false);
                setFormData({
                    type: '',
                    description: '',
                    amount: (0).toFixed(2),
                    comment: ''
                });
                fetchIncomeExpenses();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving income/expense:', error);
            }
            log.error('Error saving income/expense:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleAddNewDescription = async () => {
        if (!newType) {
            setAlertMessage('Please select a Type.');
            setAlertSeverity('error');
            setAlertOpen(true);
            addDescTypeRef.current.focus();
            return;
        }
        if (!newDescription) {
            setAlertMessage('Please enter the Description.');
            setAlertSeverity('error');
            setAlertOpen(true);
            addDescRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("ND");
    };

    const handleConfirmAddNewDescription = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/incomeexpense/savedescription`, { type: newType, description: newDescription, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setNewDescription('');
                setNewType('');
                setOpenAddDescription(false);
                setFormData({
                    type: '',
                    amount: formData.amount,
                    comment: formData.comment
                });
                setDescriptions([]);
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleFocus = (ref) => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select();
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            Income/Expense Management
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            variant="contained" color="primary" sx={{ color: 'font.main' }}
                            fullWidth
                            onClick={() => setVisible(true)}
                        >
                            Add New
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Date</TableCell>
                                <TableCell style={{ color: 'white' }}>Type</TableCell>
                                <TableCell style={{ color: 'white' }}>Description</TableCell>
                                <TableCell align='right' style={{ color: 'white' }}>Amount (Rs.)</TableCell>
                                <TableCell style={{ color: 'white' }}>Comment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedIncomeExpenses.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                paginatedIncomeExpenses.map((incomeExpense) => (
                                    <TableRow hover key={incomeExpense.id}>
                                        <TableCell>{incomeExpense.date}</TableCell>
                                        <TableCell>{incomeExpense.type}</TableCell>
                                        <TableCell>{incomeExpense.description}</TableCell>
                                        <TableCell align='right'>{parseFloat(incomeExpense.amount).toFixed(2)}</TableCell>
                                        <TableCell>{incomeExpense.comment}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                {pageCount > 0 && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        style={{ marginTop: '20px' }}
                    />
                )}

                {/* Dialogs */}
                <Dialog open={visible} onClose={() => setVisible(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Add New Income/Expense</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                <FormControl fullWidth required>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        inputRef={typeRef}
                                        name="type"
                                        label="Type"
                                        value={formData.type}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="I">Income</MenuItem>
                                        <MenuItem value="E">Expense</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                                <FormControl fullWidth required>
                                    <InputLabel>Description</InputLabel>
                                    <Select
                                        inputRef={descriptionRef}
                                        name="description"
                                        label="Description"
                                        value={formData.description}
                                        onChange={handleChange}
                                    >
                                        {descriptions.map((desc) => (
                                            <MenuItem key={desc.id} value={desc.id}>
                                                {desc.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={() => setOpenAddDescription(true)} color="primary">
                                        Add New Description
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={amountRef}
                                    name="amount"
                                    label="Amount"
                                    type="text"
                                    value={formData.amount}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*\.?\d*$/.test(value)) {
                                            handleChange(e); // update value
                                        }
                                    }}
                                    onBlur={() => {
                                        const amountValue = parseFloat(formData.amount);
                                        if (isNaN(amountValue) || amountValue === 0) {
                                            handleChange({ target: { name: 'amount', value: (0.00).toFixed(2) } });
                                        } else {
                                            handleChange({ target: { name: 'amount', value: amountValue.toFixed(2) } });
                                        }
                                    }}
                                    fullWidth
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                    }}
                                    onFocus={() => handleFocus(amountRef)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    label="Comment"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setVisible(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleAddIncomeExpense} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add Description Dialog */}
                <Dialog open={openAddDescription} onClose={() => setOpenAddDescription(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Add New Description</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth required sx={{ mt: 2 }}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                inputRef={addDescTypeRef}
                                name="addDescType"
                                label="Type"
                                onChange={(e) => setNewType(e.target.value)}
                            >
                                <MenuItem value="I">Income</MenuItem>
                                <MenuItem value="E">Expense</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField sx={{ mt: 1 }}
                            inputRef={addDescRef}
                            label="Description"
                            value={newDescription}
                            onChange={(e) => setNewDescription(e.target.value)}
                            fullWidth
                            required
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAddDescription(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleAddNewDescription} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Custom Alert */}
                <CustomAlert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                {/* Confirmation Alert */}
                <CustomAlert
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}

                    message={confirmType === 'NI' ? "Do you want to add this item?" :
                        confirmType === 'ND' ? "Do you want to add this item?" : ""}

                    severity="warning"
                    //onConfirm={handleConfirmAddItem}

                    onConfirm={confirmType === 'NI' ? handleConfirmAddIncomeExpense :
                        confirmType === 'ND' ? handleConfirmAddNewDescription : null}

                    isConfirmation
                />
            </Container>
        </ThemeProvider>
    );
};

export default IncomeExpense;
