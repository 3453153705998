import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Grid,
    TableContainer,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import log from 'loglevel';

const GrnDetail = ({ open, onClose, grn }) => {
    const [grnItems, setGrnItems] = useState([]);
    log.setLevel('info');

    useEffect(() => {
        const fetchGrnItems = async () => {
            if (grn && grn.grnNo) {
                try {
                    const response = await axios.get(`${config.API_BASE_URL}/grn/getgrnitems`, { params: { grnNo: grn.grnNo } });
                    setGrnItems(response.data);
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error fetching GRN details:', error);
                    }
                    log.error('Error fetching GRN details:', error);
                }
            }
        };

        fetchGrnItems();
    }, [grn]);

    if (!grn) return null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                <Typography variant="h6" component="span">GRN Details</Typography>
            </DialogTitle>
            <DialogContent sx={{ mt: 3 }}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1"><strong>GRN No:</strong> {grn.grnNo}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1"><strong>Supplier:</strong> {grn.supplierName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1"><strong>Date:</strong> {grn.date}</Typography>
                    </Grid>
                </Grid>

                <Paper sx={{ mt: 2, borderRadius: 2 }} elevation={2}>
                    <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#424242' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }}>Item Code</TableCell>
                                    <TableCell style={{ color: 'white' }}>Item Name</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Qty</TableCell>
                                    <TableCell style={{ color: 'white' }} align="right">Buying Price (Rs.)</TableCell>
                                    <TableCell style={{ color: 'white' }} align="right">Selling Price (Rs.)</TableCell>
                                    <TableCell style={{ color: 'white' }} align="right">Subtotal (Rs.)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {grnItems.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Typography variant="body2" color="textSecondary">No items available</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    grnItems.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.itemCode}</TableCell>
                                            <TableCell>{item.itemName}</TableCell>
                                            <TableCell align="center">{item.qty}</TableCell>
                                            <TableCell align="right">{parseFloat(item.buyingPrice).toFixed(2)}</TableCell>
                                            <TableCell align="right">{parseFloat(item.sellingPrice).toFixed(2)}</TableCell>
                                            <TableCell align="right">{parseFloat(item.subtotal).toFixed(2)}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', textAlign: 'right' }}>
                    Grand Total: <span style={{ color: 'primary.main' }}>{parseFloat(grn.grandTotal).toFixed(2)}</span>
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default GrnDetail;
