import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        textAlign: 'center',
        paddingTop: '80px',
        paddingBottom: '80px',
        backgroundColor: 'background.default',
        borderRadius: '8px',
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {/* 404 Icon/Message */}
        <Typography
          variant="h2"
          color="error"
          sx={{
            fontWeight: 'bold',
            marginBottom: '20px',
          }}
        >
          404
        </Typography>

        {/* Message */}
        <Typography
          variant="h5"
          sx={{
            color: 'text.secondary',
            marginBottom: '30px',
            lineHeight: 1.5,
            fontWeight: '500',
          }}
        >
          Sorry, the page you're looking for does not exist.
        </Typography>

        {/* Button */}
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            color: 'font.main',
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '30px',
            boxShadow: 2,
            '&:hover': {
              boxShadow: 4,
            },
          }}
          onClick={() => navigate('/home')} // Redirect to home page
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
