import React, { useState, useEffect, useCallback } from 'react';
import config from '../../services/config';
import axios from 'axios';
import log from 'loglevel';

const MonthlySaleProfitReport = React.forwardRef(({ branchId, records, columns, branchName, year }, ref) => {

    const [branch, setBranch] = useState(null);
    log.setLevel('info');

    const fetchBranch = useCallback(async () => {
        try {
            const branchResponse = await axios.get(`${config.API_BASE_URL}/branch/getbranchbyid`, { params: { branchId } });
            setBranch(branchResponse.data[0]);

        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching branch:', error);
            }
            log.error('Error fetching branch:', error);
        }
    }, [branchId]);

    useEffect(() => {
        fetchBranch();
    }, [fetchBranch]);

    if (!records || !columns) {
        return <div>No record(s) found.</div>;
    }

    // Render the BillPrint component
    return (
        <div ref={ref} className="page">
            <div className="header">
                <img alt="Company Logo" className="logo" src="/Logo_Small.png" />
                {branch ? (
                    <>
                        <div className="branchname">{branch.branchName}</div>
                        <div className="address">{branch.address}</div>
                        <div className="contact">{branch.contactNo}</div>
                    </>
                ) : (
                    <div>Loading branch details...</div>
                )}
            </div>

            <div className="content">
                <div className="report-header">
                    <hr />
                    <div className="title">
                        <div>Monthly Sale & Profit Report</div>
                    </div>
                    <div className="filters">
                        <div>Branch : {branchName}</div>
                        <div>Year : {year}</div>
                    </div>
                </div>
                <div className="table-container">
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>No of Invoices</th>
                                <th style={{ textAlign: "right" }}>Total Invoice Amount (Rs.)</th>
                                <th style={{ textAlign: "right" }}>Profit (Rs.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((record, index) => (
                                <tr key={record.month || index}>
                                    <td>{record.month}</td>
                                    <td>{record.noOfInvoices}</td>
                                    <td align='right'>{record.totalInvoiceAmount}</td>
                                    <td align='right'>{record.profit}</td>
                                </tr>
                            ))}
                            {records.length > 0 && (
                                <tr style={{ fontWeight: "bold" }}>
                                    <td colSpan="3"></td>
                                    <td align="right">
                                        <hr style={{ borderTop: "1px solid black", margin: "5px 0" }} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                                            records.reduce((sum, row) => {
                                                const profit = row.profit ? Number(row.profit.toString().replace(/,/g, '')) : 0;
                                                return sum + profit;
                                            }, 0)
                                        )}
                                        <hr style={{ borderTop: "3px double black", margin: "5px 0" }} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Footer Section */}
            <div className="footer">
                <hr />
                <div>Software by VeloceCode - 0714589293 / 0773075020</div>
                <div>Copyright &copy; 2020-{new Date().getFullYear()} Lap Tech. All Rights Reserved.</div>
            </div>
        </div>
    );
});

export default MonthlySaleProfitReport;
