import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';
import config from '../../services/config';
import axios from 'axios';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const UserUpdate = ({ open, onClose, updateFormData, sysUser, refreshGrid, user }) => {

    const [userTypes, setUserTypes] = useState([]);
    const [branches, setBranches] = useState([]);
    const [updatedFormData, setUpdatedFormData] = useState(updateFormData); // Initialize with updateFormData
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);
    const [successUpdate, setSuccessUpdate] = useState(false);

    // Create refs for input fields
    const nicRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const genderRef = useRef(null);
    const mobileNoRef = useRef(null);
    const homeNoRef = useRef(null);
    const userTypeRef = useRef(null);
    const branchRef = useRef(null);

    const fetchUserTypes = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/user/getusertypes`);
            setUserTypes(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving user types:', error);
            }
            log.error('Error fetching user types:', error);
        }
    }, []);

    const fetchBranches = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/branch/getbranches`);
            setBranches(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching branches:', error);
            }
            log.error('Error fetching branches:', error);
        }
    }, []);

    useEffect(() => {
        fetchUserTypes();
        fetchBranches();
        setUpdatedFormData(updateFormData); // Set updatedFormData when the component is opened or updateFormData changes
    }, [fetchUserTypes, fetchBranches, updateFormData]);

    const validateNIC = (nic) => {
        const oldNicPattern = /^[0-9]{9}[vVxX]$/;  // Old NIC format: 9 digits + V/X
        const newNicPattern = /^[0-9]{12}$/;       // New NIC format: 12 digits

        return oldNicPattern.test(nic) || newNicPattern.test(nic);
    };

    const handleUpdateUser = async () => {

        const { nic, firstName, lastName, gender, mobileNo, homeNo, userType, branch } = updatedFormData;

        if (!nic) {
            setAlertMessage('Please enter the NIC.');
            setAlertSeverity('error');
            setAlertOpen(true);
            nicRef.current.focus();
            return;
        }
        if (!validateNIC(nic)) {
            setAlertMessage('Please enter a valid NIC.');
            setAlertSeverity('error');
            setAlertOpen(true);
            nicRef.current.focus();
            return;
        }
        if (!firstName) {
            setAlertMessage('Please enter the First Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            firstNameRef.current.focus();
            return;
        }
        if (!lastName) {
            setAlertMessage('Please enter the Last Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            lastNameRef.current.focus();
            return;
        }
        if (!gender) {
            setAlertMessage('Please select a Gender.');
            setAlertSeverity('error');
            setAlertOpen(true);
            genderRef.current.focus();
            return;
        }
        if (!mobileNo) {
            setAlertMessage('Please enter the Mobile No.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }
        if (mobileNo.length < 10) {
            setAlertMessage('Please enter a valid mobile number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }
        if (homeNo.length > 0 && homeNo.length < 10) {
            setAlertMessage('Please enter a valid home number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            homeNoRef.current.focus();
            return;
        }
        if (!userType) {
            setAlertMessage('Please select a User Type.');
            setAlertSeverity('error');
            setAlertOpen(true);
            userTypeRef.current.focus();
            return;
        }
        if (!branch) {
            setAlertMessage('Please select a Branch.');
            setAlertSeverity('error');
            setAlertOpen(true);
            branchRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("U");
    };

    const handleConfirmUpdateUser = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/user/updateuser`, { data: updatedFormData, sysUser: sysUser });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);
            setSuccessUpdate(response.data.success);

            if (response.data.success) {
                refreshGrid();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);

            if (process.env.NODE_ENV === 'development') {
                console.error('Error updating user:', error);
            }
            log.error('Error updating user:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
        if (successUpdate) {
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                <Typography variant="h6" component="span">Update User</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* NIC (Disabled) */}
                    <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <TextField
                            inputRef={nicRef}
                            name="nic"
                            label="NIC"
                            value={updatedFormData.nic} // Use updatedFormData
                            onChange={(e) => setUpdatedFormData({ ...updatedFormData, nic: e.target.value })}
                            fullWidth
                            required
                            disabled
                        />
                    </Grid>

                    {/* First Name */}
                    <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <TextField
                            inputRef={firstNameRef}
                            name="firstName"
                            label="First Name"
                            value={updatedFormData.firstName} // Use updatedFormData
                            onChange={(e) => setUpdatedFormData({ ...updatedFormData, firstName: e.target.value })}
                            fullWidth
                            required
                        />
                    </Grid>

                    {/* Last Name */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={lastNameRef}
                            name="lastName"
                            label="Last Name"
                            value={updatedFormData.lastName} // Use updatedFormData
                            onChange={(e) => setUpdatedFormData({ ...updatedFormData, lastName: e.target.value })}
                            fullWidth
                            required
                        />
                    </Grid>

                    {/* Gender */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel>Gender</InputLabel>
                            <Select
                                inputRef={genderRef}
                                name="gender"
                                label="Gender"
                                value={updatedFormData.gender} // Use updatedFormData
                                onChange={(e) => setUpdatedFormData({ ...updatedFormData, gender: e.target.value })}
                            >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Mobile No */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={mobileNoRef}
                            name="mobileNo"
                            label="Mobile No"
                            value={updatedFormData.mobileNo} // Use updatedFormData
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                    setUpdatedFormData({ ...updatedFormData, mobileNo: value });
                                }
                            }}
                            fullWidth
                            required
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>

                    {/* Home No */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputRef={homeNoRef}
                            name="homeNo"
                            label="Home No"
                            value={updatedFormData.homeNo} // Use updatedFormData
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                    setUpdatedFormData({ ...updatedFormData, homeNo: value });
                                }
                            }}
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>

                    {/* User Type */}
                    {user[0].User_Type !== "User" && user[0].User_Name !== updatedFormData.userName && (
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>User Type</InputLabel>
                                <Select
                                    inputRef={userTypeRef}
                                    name="userType"
                                    label="User Type"
                                    value={updatedFormData.userTypeId} // Use updatedFormData.userTypeId
                                    onChange={(e) => setUpdatedFormData({ ...updatedFormData, userTypeId: e.target.value })}
                                >
                                    {userTypes.map((type) => (
                                        <MenuItem key={type.userTypeId} value={type.userTypeId}>
                                            {type.userType}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/* Branch */}
                    {user[0].User_Type !== "User" && user[0].User_Name !== updatedFormData.userName && branches.length > 1 && (
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Branch</InputLabel>
                                <Select
                                    inputRef={branchRef}
                                    name="branch"
                                    label="Branch"
                                    value={updatedFormData.branchId} // Use updatedFormData.branchId
                                    onChange={(e) => setUpdatedFormData({ ...updatedFormData, branchId: e.target.value })}
                                >
                                    {branches.map((branch) => (
                                        <MenuItem key={branch.branchId} value={branch.branchId}>
                                            {branch.branchName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/* Global User */}
                    {user[0].User_Type !== "User" && user[0].User_Name !== updatedFormData.userName && branches.length > 1 && (
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={updatedFormData.globalUser === "Yes"} // Check if it's "Yes"
                                        onChange={(e) => setUpdatedFormData({ ...updatedFormData, globalUser: e.target.checked ? "Yes" : "No" })}
                                        name="globalUser"
                                    />
                                }
                                label="Global User"
                            />
                        </Grid>
                    )}

                    {/* Active Status */}
                    {user[0].User_Type !== "User" && user[0].User_Name !== updatedFormData.userName && (
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={updatedFormData.status === "Active"} // Check if it's "Active"
                                        onChange={(e) => setUpdatedFormData({ ...updatedFormData, status: e.target.checked ? "Active" : "Inactive" })}
                                        name="status"
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary" variant='outlined'>
                    Cancel
                </Button>
                <Button onClick={handleUpdateUser} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                    Update
                </Button>
            </DialogActions>

            {/* Custom Alert */}
            <CustomAlert
                open={alertOpen}
                onClose={handleAlertClose}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'U' ? "Do you want to update this user?" : ""}
                severity="warning"
                onConfirm={confirmType === 'U' ? handleConfirmUpdateUser : null}
                isConfirmation
            />

        </Dialog>
    );
};

export default UserUpdate;
