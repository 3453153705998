import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
    TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, ThemeProvider, Container, AppBar,
    Toolbar, Typography, Grid, Checkbox, Box, Button,
    InputAdornment
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ReactPaginate from 'react-paginate';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const Payment = ({ user, branchId }) => {
    const theme = useTheme();
    const [payments, setPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [selectedPayments, setSelectedPayments] = useState(new Set());
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    const [cashReceived, setCashReceived] = useState((0).toFixed(2));
    const [totalOutstanding, setTotalOutstanding] = useState(0);
    log.setLevel('info');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');
    const [confirmOpen, setConfirmOpen] = useState(false);

    const cashReceivedRef = useRef(null);

    const fetchPayments = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/payment/getallpayments`, { params: { branchId } });
            setPayments(response.data);
        } catch (error) {
            log.error('Error fetching payments:', error);
        }
    }, [branchId]);

    useEffect(() => {
        fetchPayments();
    }, [fetchPayments]);

    useEffect(() => {
        if (searchTerm) {
            const results = payments.filter(payment =>
                payment.invoiceNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                payment.customerName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredPayments(results);
        } else {
            setFilteredPayments([]); // Clear filtered payments if no search term
        }
        setSelectedPayments(new Set()); // Clear selection whenever the search term changes
    }, [searchTerm, payments]);

    useEffect(() => {
        setPageCount(Math.ceil(filteredPayments.length / rowsPerPage));
    }, [filteredPayments]);

    const paginatedPayments = filteredPayments.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    const handleSelectPayment = (paymentCode) => {
        setSelectedPayments((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(paymentCode)) {
                newSelected.delete(paymentCode);
            } else {
                newSelected.add(paymentCode);
            }
            return newSelected;
        });
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            paginatedPayments.forEach(payment => setSelectedPayments(prev => new Set(prev).add(payment.paymentId)));
        } else {
            setSelectedPayments(new Set()); // Clear selection
        }
    };

    const isPaymentSelected = useCallback((paymentCode) => selectedPayments.has(paymentCode), [selectedPayments]);

    const isAllSelected = paginatedPayments.length > 0 && selectedPayments.size === paginatedPayments.length;

    const calculateOutstandingSum = useCallback(() => {
        let total = 0;
        paginatedPayments.forEach(payment => {
            if (isPaymentSelected(payment.paymentId)) {
                total += payment.outstanding;
            }
        });
        return total;
    }, [paginatedPayments, isPaymentSelected]);

    useEffect(() => {
        setTotalOutstanding(calculateOutstandingSum());
    }, [selectedPayments, paginatedPayments, calculateOutstandingSum]); // Recalculate total outstanding when selections change

    const isCashReceivedDisabled = totalOutstanding === 0;

    const handleFocus = (ref) => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select(); // Select text on focus
        }
    };

    const handleCashReceivedChange = (value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setCashReceived(value);
        }
    };

    const handleSavePayment = () => {

        if (cashReceived <= 0) {
            setAlertMessage('Please enter a cash received greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            cashReceivedRef.current.focus();
            return;
        }

        setConfirmOpen(true);
    };

    const handleConfirmSavePayment = async () => {
        try {

            const response = await axios.post(`${config.API_BASE_URL}/payment/savepayments`, {
                selectedPayments: Array.from(selectedPayments),
                cashReceived: cashReceived,
                sysUser: user[0].User_Name
            });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setCashReceived((0).toFixed(2));
                setSelectedPayments(new Set());
                setSearchTerm('');

                await fetchPayments();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving payments:', error);
            }
            log.error('Error saving payments:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            Payments Management
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Search by Invoice No or Customer Name"
                            fullWidth
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isAllSelected}
                                        indeterminate={selectedPayments.size > 0 && selectedPayments.size < paginatedPayments.length}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell style={{ color: 'white' }}>Invoice No</TableCell>
                                <TableCell style={{ color: 'white' }}>Date</TableCell>
                                <TableCell style={{ color: 'white' }}>Customer</TableCell>
                                <TableCell style={{ color: 'white' }}>Total Invoice Amount</TableCell>
                                <TableCell style={{ color: 'white' }}>Total Credit Amount</TableCell>
                                <TableCell style={{ color: 'white' }}>Total Paid Credit Amount</TableCell>
                                <TableCell style={{ color: 'white' }}>Outstanding</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredPayments.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">No records found</TableCell>
                                </TableRow>
                            ) : (
                                paginatedPayments.map((payment) => (
                                    <TableRow key={payment.paymentId} hover>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isPaymentSelected(payment.paymentId)}
                                                onChange={() => handleSelectPayment(payment.paymentId)}
                                            />
                                        </TableCell>
                                        <TableCell>{payment.invoiceNo}</TableCell>
                                        <TableCell>{payment.date}</TableCell>
                                        <TableCell>{payment.customerName}</TableCell>
                                        <TableCell align='right'>{parseFloat(payment.grandTotal).toFixed(2)}</TableCell>
                                        <TableCell align='right'>{parseFloat(payment.TotalCreditAmount).toFixed(2)}</TableCell>
                                        <TableCell align='right'>{parseFloat(payment.paidCreditAmount).toFixed(2)}</TableCell>
                                        <TableCell align='right'>{parseFloat(payment.outstanding).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                {pageCount > 0 && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        style={{ marginTop: '20px' }}
                    />
                )}

                {/* Summary and Save Payment */}
                <Box sx={{ mt: 3, p: 2, bgcolor: '#f5f5f5', borderRadius: 1, boxShadow: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Total Outstanding: {parseFloat(totalOutstanding).toFixed(2)}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Cash Received"
                                value={cashReceived}
                                onChange={(e) => handleCashReceivedChange(e.target.value)}
                                disabled={isCashReceivedDisabled}
                                sx={{
                                    width: '150px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                    },
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                }}
                                inputRef={cashReceivedRef}
                                onFocus={() => handleFocus(cashReceivedRef)}
                                onBlur={() => {
                                    if (!cashReceived) {
                                        setCashReceived((0.00).toFixed(2));
                                    } else {
                                        const formattedValue = parseFloat(cashReceived).toFixed(2);
                                        setCashReceived(formattedValue);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained" color="primary" sx={{ color: 'font.main', height: '56px' }}
                                onClick={handleSavePayment}
                                disabled={isCashReceivedDisabled
                                }
                            >
                                Save Payment
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                {/* Custom Alert */}
                <CustomAlert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                {/* Confirmation Alert */}
                <CustomAlert
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    message={"Do you want to save this payment?"}
                    severity="warning"
                    onConfirm={handleConfirmSavePayment}
                    isConfirmation
                />
            </Container>
        </ThemeProvider>
    );
};

export default Payment;
