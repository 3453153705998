import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Checkbox,
    FormControlLabel,
    Typography,
    Card,
    CardContent,
    InputAdornment,
} from '@mui/material';

const Payment = ({ subTotal, excessFunds, onPaymentDataChange, cashReceivedRef, cashRef, cashAmountRef,
    creditRef, creditAmountRef, creditPeriodRef, totalDiscountRef, creditAllow, clearPayment }) => {
    const [paymentMethods, setPaymentMethods] = useState({ cash: false, credit: false });
    const [creditPeriod, setCreditPeriod] = useState('0');
    const [discount, setDiscount] = useState((0.00).toFixed(2));
    const [creditAmount, setCreditAmount] = useState((0.00).toFixed(2));
    const [cashAmount, setCashAmount] = useState((0.00).toFixed(2));
    const [cashReceived, setCashReceived] = useState((0.00).toFixed(2));
    const [balance, setBalance] = useState((0.00).toFixed(2));
    const [grandTotal, setGrandTotal] = useState((0.00).toFixed(2));

    useEffect(() => {
        if (!creditAllow) {
            setPaymentMethods({ cash: true, credit: false });
        } else {
            setPaymentMethods({ cash: false, credit: false });
        }
    }, [creditAllow]);

    useEffect(() => {
        const total = (subTotal - discount - excessFunds).toFixed(2);
        setGrandTotal(total);
    }, [subTotal, discount, excessFunds]);

    useEffect(() => {
        if (subTotal !== 0) {
            setDiscount((0.00).toFixed(2));
        }
    }, [subTotal]);

    useEffect(() => {
        if (paymentMethods.cash && paymentMethods.credit && parseFloat(cashAmount) > 0) {
            const creditAmt = (parseFloat(grandTotal) - parseFloat(cashAmount));
            setCreditAmount(creditAmt >= 0 ? creditAmt : (0.00).toFixed(2));
        }
    }, [paymentMethods.cash, paymentMethods.credit, grandTotal, cashAmount]);

    useEffect(() => {
        if (paymentMethods.cash && paymentMethods.credit && parseFloat(creditAmount) > 0) {
            const cashAmt = (parseFloat(grandTotal) - parseFloat(creditAmount));
            setCashAmount(cashAmt >= 0 ? cashAmt : (0.00).toFixed(2));
        }
    }, [paymentMethods.cash, paymentMethods.credit, grandTotal, creditAmount]);

    useEffect(() => {
        // Calculate balance when cash received or cash amount changes
        const calcBalance = (parseFloat(cashReceived) - parseFloat(cashAmount)).toFixed(2);
        setBalance(calcBalance >= 0 ? calcBalance : (0.00).toFixed(2));
    }, [cashReceived, cashAmount]);

    useEffect(() => {
        if (clearPayment) {
            if (!creditAllow) {
                setPaymentMethods({ cash: true, credit: false });
            } else {
                setPaymentMethods({ cash: false, credit: false });
            }
            setCreditPeriod('0');
            setDiscount((0.00).toFixed(2));
            setCreditAmount((0.00).toFixed(2));
            setCashAmount((0.00).toFixed(2));
            setCashReceived((0.00).toFixed(2));
            setBalance((0.00).toFixed(2));
            setGrandTotal((0.00).toFixed(2));
        }
    }, [clearPayment, creditAllow]);

    useEffect(() => {
        onPaymentDataChange({
            subTotal,
            excessFunds,
            discount,
            grandTotal,
            paymentMethods,
            creditPeriod,
            creditAmount,
            cashAmount,
            cashReceived,
            balance,

        });
    }, [subTotal, paymentMethods, creditPeriod, discount, excessFunds, creditAmount, cashAmount, cashReceived, balance, grandTotal, onPaymentDataChange]);

    useEffect(() => {
        // Reset payment methods and amounts when grandTotal changes
        if (!creditAllow) {
            setPaymentMethods({ cash: true, credit: false });
            setCashAmount(grandTotal);
            setCashReceived((0.00).toFixed(2));  // Assume cash received is equal to grand total initially
            setCreditAmount((0.00).toFixed(2));
        } else {
            setPaymentMethods({ cash: false, credit: false });
            setCashAmount((0.00).toFixed(2));
            setCashReceived((0.00).toFixed(2));
            setCreditAmount((0.00).toFixed(2));
            setCreditPeriod(0);
        }
    }, [grandTotal, creditAllow]);


    const handlePaymentMethodChange = (method) => {
        setPaymentMethods(prev => {
            const updatedMethods = { ...prev, [method]: !prev[method] };

            // If both methods are selected, set amounts to 0
            if (updatedMethods.cash && updatedMethods.credit) {
                setCashAmount((0.00).toFixed(2));
                setCashReceived((0.00).toFixed(2));
                setCreditAmount((0.00).toFixed(2));
                setCreditPeriod(0);
            } else if (updatedMethods.cash && !updatedMethods.credit) {
                // If only cash is selected, set grand total to cash amount
                setCashAmount(grandTotal);
                setCashReceived((0.00).toFixed(2));  // Assume cash received is equal to grand total initially
                setCreditAmount((0.00).toFixed(2)); // Reset credit amount
            } else if (!updatedMethods.cash && updatedMethods.credit) {
                // If only credit is selected, set grand total to credit amount
                setCreditAmount(grandTotal);
                setCashAmount((0.00).toFixed(2)); // Reset cash amount
                setCashReceived((0.00).toFixed(2)); // Reset cash received
            } else {
                // If no method is selected, reset both amounts
                setCashAmount((0.00).toFixed(2));
                setCashReceived((0.00).toFixed(2));
                setCreditAmount((0.00).toFixed(2));
                setCreditPeriod(0);
            }

            return updatedMethods;
        });
    };


    const handleDiscountChange = (value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setDiscount(value);
        }
    };

    const handleCashReceivedChange = (value) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setCashReceived(value);
        }
    };

    const handleFocus = (ref) => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select(); // Select text on focus
        }
    };

    return (
        <Card elevation={3} style={{ marginTop: '20px' }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Payment Details
                </Typography>

                <Grid container spacing={3}>

                    {/* Subtotal and Discount */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Sub Total"
                            variant="outlined"
                            fullWidth
                            value={subTotal}
                            disabled
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                        />
                        <TextField
                            label="Total Discount"
                            variant="outlined"
                            fullWidth
                            value={discount}
                            onChange={(e) => handleDiscountChange(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                            inputRef={totalDiscountRef}
                            onFocus={() => handleFocus(totalDiscountRef)}
                            onBlur={() => {
                                if (!discount) {
                                    setDiscount((0.00).toFixed(2));
                                } else {
                                    const formattedValue = parseFloat(discount).toFixed(2);
                                    setDiscount(formattedValue);
                                }
                            }}
                        />
                    </Grid>

                    {/* Excess Funds and Grand Total */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Excess Funds"
                            variant="outlined"
                            fullWidth
                            value={parseFloat(excessFunds).toFixed(2)}
                            disabled
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                        />
                        <TextField
                            label="Grand Total"
                            variant="outlined"
                            fullWidth
                            value={grandTotal}
                            disabled
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                        />
                    </Grid>

                    {/* Payment Method */}
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={paymentMethods.cash}
                                    onChange={() => handlePaymentMethodChange('cash')}
                                    color="primary"
                                    inputRef={cashRef}
                                />
                            }
                            label="Cash Payment"
                        />
                        <TextField
                            label="Cash Amount"
                            variant="outlined"
                            fullWidth
                            value={cashAmount}
                            onChange={(e) => {
                                const value = e.target.value;
                                const regex = /^\d*\.?\d{0,2}$/; // Regex to allow only numbers and up to 2 decimal places

                                if (regex.test(value)) {
                                    setCashAmount(value);
                                }
                            }}
                            disabled={!paymentMethods.cash || !paymentMethods.credit}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                            inputRef={cashAmountRef}
                            onFocus={() => handleFocus(cashAmountRef)}
                            onBlur={() => {
                                if (!cashAmount) {
                                    setCashAmount((0.00).toFixed(2));
                                } else {
                                    const formattedValue = parseFloat(cashAmount).toFixed(2);
                                    setCashAmount(formattedValue);
                                }
                            }}
                        />
                        <TextField
                            label="Cash Received"
                            variant="outlined"
                            fullWidth
                            value={cashReceived}
                            disabled={!paymentMethods.cash}
                            onChange={(e) => handleCashReceivedChange(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                            inputRef={cashReceivedRef}
                            onFocus={() => handleFocus(cashReceivedRef)}
                            onBlur={() => {
                                if (!cashReceived) {
                                    setCashReceived((0.00).toFixed(2));
                                } else {
                                    const formattedValue = parseFloat(cashReceived).toFixed(2);
                                    setCashReceived(formattedValue);
                                }
                            }}
                        />
                        <TextField
                            label="Balance"
                            variant="outlined"
                            fullWidth
                            value={balance}
                            disabled
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={paymentMethods.credit}
                                    onChange={() => handlePaymentMethodChange('credit')}
                                    color="primary"
                                    inputRef={creditRef}
                                    disabled={!creditAllow}
                                />
                            }
                            label="Credit Payment"
                        />
                        <TextField
                            label="Credit Amount"
                            variant="outlined"
                            fullWidth
                            value={creditAmount}
                            onChange={(e) => {
                                // Allow only numbers and one decimal point
                                const value = e.target.value;
                                const regex = /^\d*\.?\d{0,2}$/; // Regex to allow only numbers and up to 2 decimal places

                                if (regex.test(value)) {
                                    setCreditAmount(value);
                                }
                            }}
                            disabled={!paymentMethods.cash || !paymentMethods.credit}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            }}
                            margin="normal"
                            inputRef={creditAmountRef}
                            onFocus={() => handleFocus(creditAmountRef)}
                            onBlur={() => {
                                if (!creditAmount) {
                                    setCreditAmount((0.00).toFixed(2));
                                } else {
                                    const formattedValue = parseFloat(creditAmount).toFixed(2);
                                    setCreditAmount(formattedValue);
                                }
                            }}
                        />
                        <TextField
                            label="Credit Period"
                            variant="outlined"
                            fullWidth
                            value={creditPeriod}
                            onChange={(e) => {
                                // Allow only integer values (numbers)
                                const value = e.target.value;
                                const regex = /^\d*$/; // Regex to allow only digits (no decimals)

                                if (regex.test(value)) {
                                    setCreditPeriod(value);
                                }
                            }}
                            disabled={!paymentMethods.credit}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">Days</InputAdornment>,
                            }}
                            margin="normal"
                            inputRef={creditPeriodRef}
                            onFocus={() => handleFocus(creditPeriodRef)}
                            onBlur={() => {
                                if (!creditPeriod) {
                                    setCreditPeriod(0);
                                }
                            }}
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};

export default Payment;
