import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, ThemeProvider, Container, AppBar, Toolbar, Typography, Grid,
    InputAdornment,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ReactPaginate from 'react-paginate';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const CashDetails = ({ user, branchId }) => {
    const theme = useTheme();
    const [cashDetails, setCashDetails] = useState([]);
    const [visible, setVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);

    // Create refs for input fields
    const cashFloatRef = useRef(null);
    const ownersDrawRef = useRef(null);

    const [formData, setFormData] = useState({
        cashFloat: (0.00).toFixed(2),
        ownersDraw: (0.00).toFixed(2),
    });

    const fetchCashDetails = useCallback(async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/cashdetails/getcashdetails`, { params: { branchId } });
            setCashDetails(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching cashDetails:', error);
            }
            log.error('Error fetching cashDetails:', error);
        }
    }, [branchId]);

    useEffect(() => {
        fetchCashDetails();
    }, [fetchCashDetails]);

    const filteredCashDetail = cashDetails.filter(cashDetail =>
        cashDetail.date.includes(searchTerm.toLowerCase()));

    useEffect(() => {
        setPageCount(Math.ceil(filteredCashDetail.length / rowsPerPage));
    }, [filteredCashDetail]);

    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    const paginatedCashDetails = filteredCashDetail.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    };

    const handleAddCashDetails = async () => {
        const { cashFloat, ownersDraw } = formData;

        if (parseFloat(cashFloat) <= 0) {
            setAlertMessage('Please enter a cash float greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            cashFloatRef.current.focus();
            return;
        }

        if (parseFloat(ownersDraw) <= 0) {
            setAlertMessage("Please enter a owner's draw greater than 0.");
            setAlertSeverity('error');
            setAlertOpen(true);
            ownersDrawRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setVisible(true);

    };

    const handleConfirmAddCashDetails = async () => {

        try {

            const response = await axios.post(`${config.API_BASE_URL}/cashdetails/savecashdetails`,
                { cashDetail: formData, branchId, loginId: user[0].Login_Id });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setVisible(false);
                setFormData({
                    cashFloat: (0.00).toFixed(2),
                    ownersDraw: (0.00).toFixed(2),
                });
                fetchCashDetails();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving cash details:', error);
            }
            log.error('Error saving cash details:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleFocus = (ref) => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select(); // Select text on focus
        }
    };

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                if (cashFloatRef.current) {
                    cashFloatRef.current.focus();
                }
            }, 0);
            return () => clearTimeout(timer);
        }
    }, [visible]);

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            Cash Management
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            variant="contained" color="primary" sx={{ color: 'font.main' }}
                            fullWidth
                            onClick={() => setVisible(true)}
                        >
                            Add New
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Date</TableCell>
                                <TableCell align='right' style={{ color: 'white' }}>Cash Float</TableCell>
                                <TableCell align='right' style={{ color: 'white' }}>Owner's Draw</TableCell>
                                <TableCell style={{ color: 'white' }}>Entered User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedCashDetails.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                paginatedCashDetails.map((cashDetail) => (
                                    <TableRow key={cashDetail.date} hover>
                                        <TableCell>{cashDetail.date}</TableCell>
                                        <TableCell align='right'>{parseFloat(cashDetail.cashFloat).toFixed(2)}</TableCell>
                                        <TableCell align='right'>{parseFloat(cashDetail.ownersDraw).toFixed(2)}</TableCell>
                                        <TableCell>{cashDetail.enteredUser}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                {pageCount > 0 && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        style={{ marginTop: '20px' }}
                    />
                )}

                {/* Dialogs */}
                <Dialog open={visible} onClose={() => setVisible(false)}>
                    <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <Typography variant="h6" component="span">Add New Cash Details</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                                <TextField
                                    inputRef={cashFloatRef}
                                    name="cashFloat"
                                    label="Cash Float"
                                    type="text"
                                    value={formData.cashFloat}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    onKeyDown={(event) => {
                                        if (!/[0-9.]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onBlur={() => {
                                        const cashFloatValue = parseFloat(formData.cashFloat);
                                        if (isNaN(cashFloatValue) || cashFloatValue === 0) {
                                            setFormData(prev => ({ ...prev, cashFloat: (0.00).toFixed(2) }));
                                        } else {
                                            setFormData(prev => ({ ...prev, cashFloat: cashFloatValue.toFixed(2) }));
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                    }}
                                    onFocus={() => handleFocus(cashFloatRef)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                                <TextField
                                    inputRef={ownersDrawRef}
                                    name="ownersDraw"
                                    label="Owners Draw"
                                    type="text"
                                    value={formData.ownersDraw}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    onKeyDown={(event) => {
                                        if (!/[0-9.]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onBlur={() => {
                                        const ownersDrawValue = parseFloat(formData.ownersDraw);
                                        if (isNaN(ownersDrawValue) || ownersDrawValue === 0) {
                                            setFormData(prev => ({ ...prev, ownersDraw: (0.00).toFixed(2) }));
                                        } else {
                                            setFormData(prev => ({ ...prev, ownersDraw: ownersDrawValue.toFixed(2) }));
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                    }}  
                                    onFocus={() => handleFocus(ownersDrawRef)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setVisible(false)} color="primary" variant='outlined'>
                            Cancel
                        </Button>
                        <Button onClick={handleAddCashDetails} variant="contained" color="primary" sx={{ color: 'font.main' }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Custom Alert */}
                <CustomAlert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                {/* Confirmation Alert */}
                <CustomAlert
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    message={"Do you want to add this cash details?"}
                    severity="warning"
                    onConfirm={handleConfirmAddCashDetails}
                    isConfirmation
                />

            </Container>
        </ThemeProvider>
    );
};

export default CashDetails;
